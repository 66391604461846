import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import { SET_DOMEINS, SET_THEMES, DELETE_DOMEIN } from './types';
import { helperError } from '../../core/helpers/helperError';

export const getDomeins = (page, id, name) => async (dispatch) => {
    try {
        const response = await API.get(
            `/microservices/servers-managment/api/v1/domains/?filter[server_id]=${id}&page=${page}&filter[name]=${name}`,
        );
        dispatch({ type: SET_DOMEINS, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getThemes = () => async (dispatch) => {
    try {
        const { data } = await API.get('/admin/generators/topics');
        dispatch({ type: SET_THEMES, payload: data });
    } catch (e) {
        helperError(e);
    }
};

export const createDomein = (options, user) => async () => {
    try {
        await API.post(`/admin/generators/domains/${user}`, options);
    } catch (e) {
        helperError(e);
    }
};

export const uploadFilesOnServerFtp = (domain) => async () => {
    try {
        await API.post(`/admin/generators/domains/${domain}/upload-files-on-ftp-server`);
        NotificationManager.success(`Success`, '', 3500);
    } catch (e) {
        helperError(e);
    }
};

export const deleteDomein = (domein) => async (dispatch) => {
    try {
        await API.delete(`/admin/generators/domains/${domein}`);
        dispatch({ type: DELETE_DOMEIN, payload: domein });
    } catch (e) {
        helperError(e);
    }
};
