import classNames from 'classnames';
import './styles.scss';
import { Spinner } from 'react-bootstrap';

const Button = ({ title = '', onClick, blue = false, isDisabled = false, className, isLoading = false }) => {
    return (
        <button
            className={classNames('button-silver', className, {
                ['button-blue']: blue && !isDisabled,
                ['disabled']: isDisabled,
            })}
            onClick={onClick}
            disabled={isDisabled}
        >
            {isLoading ? <Spinner size="sm" animation="border" /> : title}
        </button>
    );
};

export default Button;
