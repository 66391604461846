import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import UIFormInput from '../../UI/UIFormInput';
import CustomDatePicker from '../../UI/CustomDatePicker';
import { useState } from 'react';
import SelectSearch from '../../UI/Select';
import { optionsGeo } from '../../../utils/data';
import { useDispatch } from 'react-redux';
import { createServer } from '../../../pages/SysAdmin/action';
import moment from 'moment';

const ModalCreateServer = ({ closeModal, show, loadServer }) => {
    const [build, setBuild] = useState({
        ip: '',
        start_date: null,
        expiration_date: null,
        host_provider_name: '',
        geolocation: null,
        port: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleClickCreate = async () => {
        setIsLoading(true);
        const options = {
            ip: build.ip,
            start_date: moment(build.start_date).format('Y-MM-DD HH:mm:ss'),
            expiration_date: moment(build.expiration_date).format('Y-MM-DD HH:mm:ss'),
            host_provider_name: build.host_provider_name,
            geolocation: build.geolocation,
            port: build.port,
        };
        const resp = await dispatch(createServer(options));
        if (resp) {
            setBuild({
                ip: '',
                start_date: null,
                expiration_date: null,
                host_provider_name: '',
                geolocation: null,
                port: '',
            });
            setIsLoading(false);
            loadServer();
        }
        setIsLoading(false);
    };

    const closedModal = () => {
        setBuild({
            ip: '',
            start_date: null,
            expiration_date: null,
            host_provider_name: '',
            geolocation: null,
        });
        closeModal();
    };

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;
        setBuild({ ...build, [key]: value });
    };

    const handleChangeDate = (key, date) => {
        setBuild({ ...build, [key]: date });
    };

    const handleChangeSelect = (value) => {
        setBuild({ ...build, geolocation: value });
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        if (build.expiration_date === null) return true;
        if (build.ip.length === 0) return true;
        if (build.start_date === null) return true;
        if (build.host_provider_name.length === 0) return true;
        if (build.geolocation === null) return true;
        return false;
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-server">
            <Modal.Header closeButton>
                <Modal.Title>Добавить сервер</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="Server">
                    <Row>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="ip"
                                label={'IP Server'}
                                value={build?.ip ? build?.ip : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <CustomDatePicker
                                title={'Дата покупки'}
                                onChange={(e) => handleChangeDate('start_date', e)}
                                value={build.start_date}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="host_provider_name"
                                label={'Name Hosting Provider'}
                                value={build?.host_provider_name ? build?.host_provider_name : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <CustomDatePicker
                                title={'Срок дейсвия'}
                                onChange={(e) => handleChangeDate('expiration_date', e)}
                                value={build.expiration_date}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <SelectSearch
                                onChange={handleChangeSelect}
                                title={'Geo'}
                                options={optionsGeo}
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={build?.geolocation}
                                value={build?.geolocation}
                            />
                        </Col>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="port"
                                label={'Port'}
                                value={build?.port ? build?.port : ''}
                            />
                        </Col>
                    </Row>
                    <div className="Server-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            onClick={handleClickCreate}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Добавить'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreateServer;
