import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import { CHANGE_PAGE, SET_SERVER, SET_TOPIC } from './types';
import { helperError } from '../../core/helpers/helperError';

export const getServers =
    (page = 1) =>
    async (dispatch) => {
        try {
            const resp = await API.get(`/microservices/servers-managment/api/v1/servers?page=${page}`);
            dispatch({ type: SET_SERVER, payload: resp.data });
        } catch (error) {
            helperError(error);
        }
    };

export const getServersAll = (page) => async () => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/servers?page=${page}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const createServer = (options) => async (dispatch) => {
    try {
        const resp = await API.post(`/microservices/servers-managment/api/v1/servers`, options);
        NotificationManager.success(`Сервер успешно создан`, '', 3500);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const changePages = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
};

export const createDomain = (options, server) => async () => {
    try {
        const resp = await API.post(`/microservices/servers-managment/api/v1/domains/${server}`, options);
        NotificationManager.success(`Домен успешно создан`, '', 3500);

        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const editDomain = (options, domain) => async () => {
    try {
        const resp = await API.put(`/microservices/servers-managment/api/v1/domains/${domain}`, options);
        NotificationManager.success(`Домен успешно изменен`, '', 3500);

        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getTopic = () => async (dispatch) => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/topics`);
        dispatch({ type: SET_TOPIC, payload: resp.data.data });
    } catch (error) {
        helperError(error);
    }
};

export const getDomainCheck = () => async (dispatch) => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/checks`);

        if (resp.data[0]?.checks) {
            return resp.data[0]?.checks;
        }
        return [];
        // dispatch({ type: SET_TOPIC, payload: resp.data.data });
    } catch (error) {
        helperError(error);
    }
};

export const createGeneralDomain = (options) => async () => {
    try {
        const resp = await API.post(`/microservices/servers-managment/api/v1/general-domain-servers`, options);
        NotificationManager.success(`Успешно`, '', 3500);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const createPreset = (options) => async () => {
    try {
        const resp = await API.post(`/microservices/servers-managment/api/v1/presets`, options);
        NotificationManager.success(`Успешно`, '', 3500);
        return resp;
    } catch (error) {
        helperError(error);
    }
};
