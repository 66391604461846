export const dateDomeins = [
    { id: 1, domen: 'http://Domain1.com', status: 'Free', theme: 'Nature' },
    { id: 2, domen: 'http://Domain2.com', status: 'Free', theme: 'Nature' },
    { id: 3, domen: 'http://Domain3.com', status: 'Free', theme: 'Nature' },
];

export const variantRk = [
    { label: 'Витрина', value: 'Витрина' },
    { label: 'Витрина+Пуш', value: 'Витрина+Пуш' },
    { label: 'Дао', value: 'Дао' },
];

export const typeScript = [
    { label: 'GP', value: 'GP' },
    { label: 'DAO', value: 'DAO' },
    { label: 'SHOWCASE', value: 'SHOWCASE' },
];

export const infoOperation = {
    equal: 'Equal/not equal - если есть текстовое поле - проверяет соотношение введенных значений по отношению к значению пользователя. Если есть переключатель, проверяет, соответственные фильтри на ответ да или нет.',
    enter: 'Enter/Not enter – есть 2 типа. Когда имеется 1 поле заполнения – предполагается, что текст, введенный в поле, будет искаться в значении соответствующего типа. Для примера Linux из Source будет пропускать дальше при наличии/отсутствии текста Linux в User Agent. И тип 2, когда есть 2 поля для заполнения – в этом случае есть несколько вариантов заполнения: можно заполнить только левое поле – тогда будет проверяться наличие/отсутствие данного ключа в списке элементов; или заполнить оба поля – это будет проверять наличие/отсутствие комбинации данного ключа и значения в списке элементов. (Пример: Фильтр Cookie)',
    more: 'More/Less — отвечает за проверку того, больше или меньше клиентское значение введенного значения.',
    note: 'ПРИМЕЧАНИЕ: отображение может отличаться для уникальных фильтров, небольшую подсказку о таких фильтрах можно прочитать в информационном окне самого фильтра',
};
