import { vaultService } from '../../core/services/vault-service';
import Header from './header';
import Menu from './menu';
import './styles.scss';

const Template = ({ children }) => {
    const service = vaultService();
    const role = service.getItem('role');
    return (
        <div className="wrapper_template">
            <Header />
            <div className="wrapper_content">
                {role === 'admin' && <Menu />}
                {children}
            </div>
        </div>
    );
};

export default Template;
