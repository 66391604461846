import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { activatedService, changeDataService, getAccountsBinom } from '../../Binom/Services/actions';
import { ReactComponent as Question } from '../../../assets/images/question.svg';
import SelectSearchMulti from '../../UI/SelectIsMulti';
import './ServiceForm.scss';
import { getStaticRk } from '../../../pages/Bayer/actions';
import { vaultService } from '../../../core/services/vault-service';
import { useParams } from 'react-router-dom';
import SelectSearch from '../../UI/Select';
import { Tooltip } from 'react-tooltip';

const ModalActivatedService = ({ closeModal, show, service, loadData, edit }) => {
    const [value, setValue] = useState('');
    const [build, setBuild] = useState(null);
    const [fields, setFields] = useState([]);
    const [country, setCountry] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [accountsOptions, setAccountsOptions] = useState([]);
    const params = useParams();

    const dispatch = useDispatch();

    const handleChangeValue = (e) => {
        const { id, value } = e.target;

        setBuild({ ...build, [id]: value });
    };

    const vaultServices = vaultService();

    const { id } = vaultServices.getItem('user');

    const loadStatic = async () => {
        if (edit) {
            const staticRk = await dispatch(getStaticRk(params.id));
            const accounts = await dispatch(getAccountsBinom(edit.service.id, id));
            if (accounts.length !== 0) {
                const options = accounts.map((item) => ({ label: item, value: item }));
                setAccountsOptions(options);
            }
            if (staticRk) {
                const language = Object.entries(staticRk?.language).map(([key, value]) => ({ label: value, value: key }));
                const country = Object.entries(staticRk?.country).map(([key, value]) => ({
                    label: value,
                    value: key,
                }));

                setCountry(country);
                setLanguages(language);
            }
            return;
        }

        const staticRk = await dispatch(getStaticRk(params.id));
        const accounts = await dispatch(getAccountsBinom(service.id, id));
        if (accounts.length !== 0) {
            const options = accounts.map((item) => ({ label: item, value: item }));
            setAccountsOptions(options);
        }
        if (staticRk) {
            const language = Object.entries(staticRk?.language).map(([key, value]) => ({ label: value, value: key }));
            const country = Object.entries(staticRk?.country).map(([key, value]) => ({
                label: value,
                value: key,
            }));

            setCountry(country);
            setLanguages(language);
        }
    };

    const handleApplyActivatedService = async () => {
        if (edit) {
            const resp = await dispatch(changeDataService(build, edit.id, params?.id));
            if (resp) {
                await loadData();
                setValue('');
                closeModal();
            }
            return;
        }

        const resp = await dispatch(activatedService(build, service.id, params?.id));
        if (resp) {
            await loadData();
            setBuild(null);
            closeModal();
        }
    };

    const formatPlaceholder = (key) => {
        return key
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const getFields = (param_rules) => {
        const arr = Object.keys(param_rules)
            .filter((key) => key !== 'user_id')
            .map((key) => {
                const values = param_rules[key];
                const type = values[values.length - 1];
                if (key === 'account') {
                    return {
                        id: key,
                        name: key,
                        required: param_rules[key].includes('required'),
                        placeholder: formatPlaceholder(key),
                        type: 'account',
                    };
                }
                return {
                    id: key,
                    name: key,
                    required: param_rules[key].includes('required'),
                    placeholder: formatPlaceholder(key),
                    type: type,
                };
            });

        setFields(arr);
    };

    useEffect(() => {
        loadStatic();
        if (edit) {
            setBuild(edit.data);
            getFields(edit.service.param_rules);
            return;
        }
        getFields(service.param_rules);
    }, []);

    const handleChangeSelectMulti = (e, key) => {
        setBuild({ ...build, [key]: e });
    };

    const handleChangeSelect = (e, key) => {
        setBuild({ ...build, [key]: e });
    };

    const showInfoDownload = () => {
        return (
            <div className="info_norm_buyer">
                <Question className="question-icon" data-tooltip-id="question-tooltip-activated" />
                <Tooltip
                    id="question-tooltip-activated"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '350px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        {edit && (
                            <p
                                className="m-0 p-0 w-100"
                                dangerouslySetInnerHTML={{ __html: edit?.service?.info?.update?.replace(/\n/g, '<br/>') }}
                            />
                        )}
                        {!edit && (
                            <p
                                className="m-0 p-0 w-100"
                                dangerouslySetInnerHTML={{ __html: service?.info?.update?.replace(/\n/g, '<br/>') }}
                            />
                        )}
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton id="test-modal-header">
                <Modal.Title className="d-flex align-items-center gap-2">Activated Service {showInfoDownload()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="Criteria-values" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {fields.map((item) =>
                        item?.type === 'string' ? (
                            <input
                                type="text"
                                id={item.id}
                                placeholder={`${item.placeholder} ${item.required ? '*' : ''}`}
                                name={item.name}
                                value={build?.[item.name] ?? ''}
                                onChange={handleChangeValue}
                            />
                        ) : item?.type === 'account' ? (
                            <SelectSearch
                                className="ServiceForm-select"
                                styles
                                onChange={(e) => handleChangeSelect(e, item.name)}
                                options={accountsOptions}
                                itemLabel={'label'}
                                placeholder={`${item.placeholder} ${item.required ? '*' : ''}`}
                                itemValue={'value'}
                                default={build?.[item.name] ?? null}
                                values={build?.[item.name] ?? null}
                            />
                        ) : (
                            <SelectSearchMulti
                                className="ServiceForm-select"
                                onChange={(e) => handleChangeSelectMulti(e, item.name)}
                                options={item.name.includes('country') ? country : languages}
                                itemLabel={'label'}
                                placeholder={`${item.placeholder} ${item.required ? '*' : ''}`}
                                itemValue={'value'}
                                default={build?.[item.name] ?? []}
                                values={build?.[item.name] ?? []}
                            />
                        ),
                    )}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleApplyActivatedService}>
                    Apply
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalActivatedService;
