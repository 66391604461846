const ProgressBar = ({ progress }) => {
    const complete = progress.split('%')[0];

    return (
        <div className="progress" style={{ width: '100%' }}>
            <div
                className="progress-bar bg-primary progress-bar-striped"
                role="progressbar"
                aria-valuenow={complete}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: `${complete}%` }}
            />
        </div>
    );
};

export default ProgressBar;
