import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import SelectSearch from '../../UI/Select';
import UIFormInput from '../../UI/UIFormInput';
import { createGeneralDomain } from '../../../pages/SysAdmin/action';

const ModalCreateGeneralDomain = ({ closeModal, show, domain, userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [build, setBuild] = useState({
        ftp_hostname: '',
        ftp_username: '',
        ftp_password: '',
        gtag: '',
        ftp_port: '21',
        ftp_directory_path: '/',
        type: null,
    });
    const [arrayValue, setArrayValue] = useState([{ key: '', link: '' }]);

    const dispatch = useDispatch();

    const loadData = async () => {};

    useEffect(() => {
        loadData();
    }, []);

    const closedModal = () => {
        closeModal();
        setBuild({
            ftp_hostname: '',
            ftp_username: '',
            ftp_password: '',
            gtag: '',
            ftp_port: '21',
            ftp_directory_path: '/',
            type: null,
        });
        setArrayValue([{ key: '', link: '' }]);
    };

    const createGeneralDomains = async () => {
        setIsLoading(true);

        const options = {
            credentials: {
                ftp_hostname: build.ftp_hostname,
                ftp_username: build.ftp_username,
                ftp_password: build.ftp_password,
            },
            type: build.type,
            payload: {
                links: arrayValue.map((item) => {
                    return { [item.key]: item.link };
                }),
                gtag: build.gtag,
            },
        };

        if (build?.ftp_port) {
            options.credentials.ftp_port = build?.ftp_port;
        }

        if (build?.ftp_directory_path) {
            options.credentials.ftp_directory_path = build?.ftp_directory_path;
        }

        const resp = await dispatch(createGeneralDomain(options));
        if (resp) {
            closedModal();
        }

        setIsLoading(false);
    };

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;
        setBuild({ ...build, [key]: value });
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        if (build.ftp_hostname.length === 0) return true;
        if (build.ftp_password.length === 0) return true;
        if (build.ftp_username.length === 0) return true;
        if (build.gtag.length === 0) return true;
        return false;
    };

    const handleChangeSelect = (value) => {
        setBuild({ ...build, type: value });
    };

    const typeScript = [
        { label: 'GP', value: 'GP' },
        { label: 'DAO', value: 'DAO' },
    ];

    return (
        <Modal show={show} onHide={closedModal} className="modal-rk">
            <Modal.Header closeButton>
                <Modal.Title>General Domain</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="CreateRK">
                    <Row>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="ftp_hostname"
                                label={'FTP hostname'}
                                value={build?.ftp_hostname ? build?.ftp_hostname : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="ftp_username"
                                label={'FTP username'}
                                value={build?.ftp_username ? build?.ftp_username : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SelectSearch
                                onChange={handleChangeSelect}
                                title={'Type'}
                                options={typeScript}
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={build?.type}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="ftp_password"
                                label={'FTP password'}
                                value={build?.ftp_password ? build?.ftp_password : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="gtag"
                                label={'Google tag'}
                                value={build?.gtag ? build?.gtag : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="ftp_port"
                                label={'FTP port'}
                                placeholder="21"
                                value={build?.ftp_port ? build?.ftp_port : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="ftp_directory_path"
                                label={'FTP directory path'}
                                placeholder="/"
                                value={build?.ftp_directory_path ? build?.ftp_directory_path : ''}
                            />
                        </Col>
                    </Row>
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            variant="primary"
                            onClick={createGeneralDomains}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Создать'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreateGeneralDomain;
