import Select from 'react-select';

const SelectMini = ({ placeholder = '', options, onChange, value }) => {
    const smallest = {
        container: (styles) => ({
            ...styles,
            width: '100%',
            textAlign: 'left',
        }),
        control: (styles) => ({
            backgroundColor: '#FFF',
            border: '1px solid #e5e2de',
            height: '38px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            paddingTop: 0,
            position: 'relative',
        }),

        indicatorsContainer: (styles) => ({
            ...styles,
            height: '10px',
            margin: '0 auto',
            span: { display: 'none' },
            right: '-5px',
            top: '30%',
            position: 'absolute',
        }),
        menu: (styles) => ({
            ...styles,
            background: '#fff',
            position: 'absolute',
            zIndex: '999999999',
        }),

        option: (styles, { isDisabled }) => ({
            ...styles,
            'textAlign': 'center',
            'alignItems': 'center',
            'display': 'flex',
            'color': '#47474B',
            'cursor': isDisabled ? 'not-allowed' : 'pointer',

            ':active': {
                ...styles[':active'],
                background: '#6D79FF',
                color: '#fff',
            },
            ':hover': {
                ...styles[':hover'],
                backgroundColor: 'rgba(242, 240, 237, 0.5);',
            },
        }),
    };

    return <Select placeholder={placeholder} onChange={onChange} value={value} options={options} styles={smallest} />;
};

export default SelectMini;
