/* eslint-disable */

import { DELETE_DOMEIN, SET_DOMEINS, SET_THEMES } from './types';

const initialState = {
    domeins: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    themes: [],
};

const domeinsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DOMEINS:
            return { ...state, domeins: payload };
        case SET_THEMES:
            return { ...state, themes: payload };
        case DELETE_DOMEIN:
            return {
                ...state,
                domeins: state.domeins.data.filter((item) => item.id !== payload),
            };
        default:
            return state;
    }
};

export default domeinsReducer;
