import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import Button from '../../../components/UI/Button';
import { useEffect, useRef, useState } from 'react';
import ModalWhitePage from '../../../components/Modals/ModalWhitePage';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../../core/helpers/routes';
import { useNavigate } from 'react-router-dom';
import { changePagesWarm, changeStatusDomainWarm, getDomeinsFarmerWarm, setWhitePageDomain } from '../actions';
import ReactPaginate from 'react-paginate';
import { getListWhitePage } from '../../Bayer/actions';
import { Tooltip } from 'react-tooltip';
import ProgressBar from '../../../components/UI/ProgressBar';
import UIFormInput from '../../../components/UI/UIFormInput';

const MyProgrev = ({ showStatusDomain }) => {
    const { domainsWarm, pageWarm } = useSelector((state) => state.farmerReducer);
    const [activeDomain, setActiveDomain] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [isLoadingServer, setIsLoadingServer] = useState(null);
    const [activeWhitePage, setActiveWhitePage] = useState([]);
    const [name, setName] = useState('');
    const timeoutRef = useRef(null);

    let timer = null;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getDomeinsFarmerWarm(pageWarm, name));
        await loadWhitePage();
    };

    const disabledPreview = (element) => {
        if (!element.domain_data?.white_page?.white_id) return null;
        const finded = activeWhitePage.find((item) => item.id === element.domain_data?.white_page?.white_id);

        if (finded) {
            return finded;
        }
        return null;
    };

    useEffect(() => {
        loadData();

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleCloseOpenModal = () => {
        setIsShow(!isShow);
    };

    const handleClickGenerateWhitePage = (item) => {
        setActiveDomain(item);
        handleCloseOpenModal();
    };

    const handleClickShow = (id) => {
        navigate(ROUTES.showWhitePage.link.replace(':id', id));
    };

    const showWhitePage = (element) => {
        if (element.domain_data?.white_page?.white_id)
            return disabledPreview(element) !== null ? (
                <ProgressBar progress={disabledPreview(element).percent_generation} />
            ) : (
                <Button
                    title="Preview"
                    isDisabled={disabledPreview(element) !== null}
                    onClick={() => handleClickShow(element.domain_data?.white_page?.white_id)}
                />
            );
        return null;
    };

    const handleClickButtonPut = async (item) => {
        if (isLoading !== null) return;
        setIsLoading(item.id);
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: 'free',
            domain_data: item.domain_data,
            user_id: null,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
        setIsLoading(null);
    };

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesWarm(currentPage));
        await dispatch(getDomeinsFarmerWarm(currentPage, name));
    };

    const handleClickButtonSetServer = async (item) => {
        if (isLoadingServer !== null) return;
        setIsLoadingServer(item.id);
        const domain = item.id;
        const whitePage = item.domain_data?.white_page?.white_id;
        await dispatch(setWhitePageDomain(domain, whitePage));
        setIsLoadingServer(null);
    };

    const loadWhitePage = async () => {
        const resp = await dispatch(getListWhitePage());
        if (resp) {
            setActiveWhitePage(resp);
            if (resp.length !== 0) {
                runUpdateCheckbox();
            }
        }
    };

    const runUpdateCheckbox = () => {
        const INTERVAL = 15000;
        timer = setTimeout(async () => {
            const data = await dispatch(getListWhitePage());

            if (data) {
                setActiveWhitePage(data);
                if (data.length !== 0) {
                    runUpdateCheckbox();
                    return;
                }
                clearTimeout(timer);
            }
        }, INTERVAL);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeinsFarmerWarm(pageWarm, value));
        }, 300);
    };

    return (
        <div className="MyProgrev">
            <div className="d-flex flex-column">
                <Row className="border-bottom pb-3 text-bold position-relative">
                    <Col className="MyProgrev-item_header">Name</Col>
                    <Col className="MyProgrev-item_header">Topic</Col>
                    <Col className="MyProgrev-item_header">GEO</Col>
                    <Col className="MyProgrev-item_header">Status</Col>
                    <Col className="MyProgrev-item_header" style={{ marginLeft: 'auto' }}></Col>
                    <Col className="MyProgrev-item_header"></Col>
                    <Col className="MyProgrev-item_header"></Col>
                    <Col className="MyProgrev-item_header"></Col>
                    <div className="MyDomain-search">
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="name"
                            placeholder="Search domain"
                            value={name}
                        />
                    </div>
                </Row>

                {domainsWarm.data.map((item) => (
                    <Row className={`border-bottom pb-3 pt-4 d-flex`} key={item.id}>
                        <Col className="MyProgrev-item">
                            {showStatusDomain(item)}
                            {item.name}
                        </Col>
                        <Col className="MyProgrev-item">{item['topic'].topic}</Col>
                        <Col className="MyProgrev-item">{item.server.geolocation}</Col>
                        <Col className="MyProgrev-item">{item.status}</Col>
                        <Col className="MyProgrev-item" style={{ marginLeft: 'auto' }}>
                            {item?.domain_data?.white_page?.white_id && (
                                <Button
                                    title="Залить"
                                    isLoading={isLoadingServer === item.id}
                                    onClick={() => handleClickButtonSetServer(item)}
                                />
                            )}
                        </Col>
                        <Col className="MyProgrev-item" data-tooltip-id={`white-page-${item.id}`}>
                            {showWhitePage(item)}
                        </Col>
                        <Col className="MyProgrev-item">
                            <Button title="White Page" onClick={() => handleClickGenerateWhitePage(item)} />
                        </Col>
                        <Col className="MyProgrev-item">
                            <Button title="Отдать" onClick={() => handleClickButtonPut(item)} isLoading={isLoading === item.id} />
                        </Col>
                        {disabledPreview(item) !== null && (
                            <Tooltip
                                id={`white-page-${item.id}`}
                                place="top"
                                style={{
                                    zIndex: '9999999999',
                                    margin: 0,
                                    padding: '10px',
                                    paddingBottom: 0,
                                }}
                            >
                                <div className="info_norm_buyer-content">
                                    <p>Генерация: {disabledPreview(item).percent_generation}</p>
                                </div>
                            </Tooltip>
                        )}
                    </Row>
                ))}
                <div className="pagination">
                    {domainsWarm.meta?.total > domainsWarm.meta?.per_page && (
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={domainsWarm.meta?.total / domainsWarm.meta?.per_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={1}
                            onPageChange={changePage}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageWarm - 1}
                        />
                    )}
                </div>
                {isShow && (
                    <ModalWhitePage
                        show={isShow}
                        closeModal={handleCloseOpenModal}
                        activeDomain={activeDomain}
                        loadData={loadData}
                    />
                )}
            </div>
        </div>
    );
};

export default MyProgrev;
