import { ReactComponent as Delete } from '../../../assets/images/delete.svg';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import { ReactComponent as ArrowSort } from '../../../assets/images/arrowSort.svg';
import { ReactComponent as Question } from '../../../assets/images/question.svg';

import './styles.scss';
import OutlinedDropdown from '../../UI/LandingSelect';
import { Tooltip } from 'react-tooltip';

const Landings = ({ landings, setLandings, optionsLandings }) => {
    const handleChangeActive = (active, id) => {
        const value = active === 1 ? 0 : 1;
        const mapping = landings.default_result_page.map((item, index) => {
            if (id === index) {
                return { ...item, active: value };
            }
            return item;
        });
        setLandings({ ...landings, default_result_page: mapping });
    };

    const handleChangeInput = (e, id, name) => {
        const mapping = landings.default_result_page.map((item, index) => {
            if (id === index) {
                return { ...item, [name]: e.target.value };
            }
            return item;
        });

        setLandings({ ...landings, default_result_page: mapping });
    };

    const handleChangeSelect = (e, id) => {
        if (e.label.includes('Service')) {
            const mapping = landings.default_result_page.map((item, index) => {
                if (id === index) {
                    return { ...item, result_type: e.value, data: e.label };
                }
                return item;
            });

            setLandings({ ...landings, default_result_page: mapping });

            return;
        }

        const mapping = landings.default_result_page.map((item, index) => {
            if (id === index) {
                return { ...item, result_type: e.value };
            }
            return item;
        });

        setLandings({ ...landings, default_result_page: mapping });
    };

    const deleteLandings = (id) => {
        const filtered = landings.default_result_page.filter((item, index) => index !== id);
        setLandings({ ...landings, default_result_page: filtered });
    };

    const handleClickAddedLand = () => {
        const land = { data: 'http://', active: 1, weight: 100, result_type: 1 };

        setLandings({
            ...landings,
            default_result_page: [...landings.default_result_page, land],
        });
    };
    const moveItemUpRules = (fromIndex, toIndex) => {
        const newArray = [...landings.default_result_page];
        const item = newArray.splice(fromIndex, 1)[0];
        newArray.splice(toIndex, 0, item);

        if (toIndex === 0) {
            return;
        }

        setLandings({ ...landings, default_result_page: newArray });
        return newArray;
    };

    const moveItemDownRules = (fromIndex) => {
        const toIndex = fromIndex + 1;
        if (toIndex >= landings.default_result_page.length) {
            return;
        }
        moveItemUpRules(fromIndex, toIndex);
    };

    const handleChangeCheckBoxSplit = (active) => {
        const value = active === 1 ? 0 : 1;

        setLandings({
            ...landings,
            default_result_split: value,
        });
    };

    const valueType = (id) => {
        const el = optionsLandings.find((item) => item.value === id);

        if (el) {
            return el;
        }

        return null;
    };

    const showInfoDefault = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-default" className="question-icon" />
                <Tooltip
                    id="question-tooltip-default"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Данный блок настраивает отображение контента при условии прохождения клоакино и без совпадения среди
                            активных групп фильтров. Примечание: если оставить блок пустым будет отображаться white page
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoNormal = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-normal-default" className="question-icon" />
                <Tooltip
                    id="question-tooltip-normal-default"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">Поочередная выдача страниц</p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoSplit = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-split-default" className="question-icon" />
                <Tooltip
                    id="question-tooltip-split-default"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">Раздельная выдача страниц</p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoResult = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-result-default" className="question-icon" />
                <Tooltip
                    id="question-tooltip-result-default"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Результат может быть отображен в нескольких типах: содержимое страницы, один из доступных редиректов.
                            Также есть специальные типы, которые доступны при активации сервисов (они имеют Service в названии)
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <div className="Landing">
            <div className="Landing-header">
                <div className="d-flex align-items-center gap-2">
                    <h3>Default</h3>
                    {showInfoDefault()}
                </div>
                <div className="Rules-container-content-item-split">
                    <div className="d-flex align-items-center gap-2">
                        {showInfoNormal()}
                        <span>Normal</span>
                    </div>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={landings ? (landings.default_result_split === 1 ? true : false) : false}
                            onChange={() => handleChangeCheckBoxSplit(landings ? landings.default_result_split : 1)}
                        />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <span>Split</span>
                        {showInfoSplit()}
                    </div>
                </div>
            </div>
            <div className="Landing-container">
                <div className="Landing-container-header">
                    <div className="Landing-container-header-title">
                        <span>Landings</span>
                    </div>
                    <span>Weight</span>
                    <span>Active</span>
                </div>
                <div className="Landing-container-content">
                    {landings !== null &&
                        (landings.default_result_page || []).map((item, indexLand) => (
                            <div key={indexLand} className="wrapper-container-content-wrapper">
                                <div className="wrapper-container-content-item">
                                    <div className="wrapper-container-content-item-title">
                                        <div className="wrapper-container-content-item-title-stripe" />
                                        <input
                                            className="input-edit"
                                            type="text"
                                            disabled={valueType(item?.result_type)?.label?.includes('Service')}
                                            value={item.data}
                                            onChange={(e) => handleChangeInput(e, indexLand, 'data')}
                                        />
                                    </div>
                                    <div className="mr-4 d-flex align-items-center gap-2">
                                        {showInfoResult()}
                                        <OutlinedDropdown
                                            onSelect={(e) => handleChangeSelect(e, indexLand)}
                                            options={optionsLandings}
                                            defaultSelected={valueType(item?.result_type)}
                                        />
                                    </div>
                                    <div className="Rules-container-content-item-icons">
                                        <div>
                                            <ArrowSort
                                                onClick={() => moveItemUpRules(indexLand - 1, indexLand)}
                                                className="arrow-sort"
                                            />
                                            <ArrowSort
                                                onClick={() => moveItemDownRules(indexLand)}
                                                className="arrow-sort-reverse"
                                            />
                                        </div>
                                    </div>
                                    {landings.default_result_split === 1 && (
                                        <div className="wrapper-container-content-item-weight">
                                            <input
                                                className="input-edit-weight"
                                                type="text"
                                                value={item.weight}
                                                onChange={(e) => handleChangeInput(e, indexLand, 'weight')}
                                            />
                                        </div>
                                    )}
                                    <div className="wrapper-container-content-item-icons">
                                        <Delete style={{ cursor: 'pointer' }} onClick={() => deleteLandings(indexLand)} />
                                    </div>
                                    <div className="wrapper-container-content-item-active">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={item.active === 1 ? true : false}
                                                onChange={(e) => handleChangeActive(item.active, indexLand)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    <div className="wrapper-button-added-path">
                        <button onClick={handleClickAddedLand} className="wrapper-button-added-path-button">
                            <span>Landings</span>
                            <Add />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landings;
