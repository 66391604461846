import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';

import thunk from 'redux-thunk';
import domeinsReducer from '../pages/Domeins/reducer';
import whitePageReducer from '../components/GeneratorWhitePage/reducer';
import serverReducer from '../pages/SysAdmin/reducer';
import farmerReducer from '../pages/Farmer/reducer';
import buyerReducer from '../pages/Bayer/reducer';
import webMasterReducer from '../pages/WebMaster/reducer';
import adminReducer from '../pages/SuperAdmin/reducer';
import aggregatorReducer from '../pages/AggregatorMail/reducer';

const rootReducer = combineReducers({
    domeinsReducer: domeinsReducer,
    whitePageReducer: whitePageReducer,
    serverReducer: serverReducer,
    farmerReducer: farmerReducer,
    buyerReducer: buyerReducer,
    webMasterReducer: webMasterReducer,
    adminReducer: adminReducer,
    aggregatorReducer: aggregatorReducer,
});

const composeEnhancers = compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
