import { InputGroup } from 'react-bootstrap';
import React from 'react';
import './styles.scss';

const UIFormTextaria = ({ value, onChange, label, className, placeholder, classNameTitle, id, name, rows = 1 }) => {
    return (
        <div className={`d-flex flex-column p-1 ${className}`}>
            <span className={`pb-1 font-weight-bold ${classNameTitle}`}>{label}</span>
            <InputGroup className="align-items-center">
                <textarea
                    className={`form-control input`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    id={id}
                    name={name}
                    rows={rows}
                />
            </InputGroup>
        </div>
    );
};

export default UIFormTextaria;
