import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import SelectSearch from '../../UI/Select';
import { activatedUser, setRoleUser } from '../../../pages/SuperAdmin/actions';

const ModalActivatedUser = ({ closeModal, show, user, loadData }) => {
    const roles = useSelector((state) => state.adminReducer.roles);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const dispatch = useDispatch();

    const closedModal = () => {
        closeModal();
        setSelectedRole(null);
    };

    const handleChangeSelect = (value) => {
        setSelectedRole(value);
    };

    const connectGeneralDomain = async () => {
        setIsLoading(true);
        const resp = await dispatch(activatedUser(user.id));
        if (resp) {
            const response = await dispatch(setRoleUser(user.id, selectedRole));
            if (response) {
                closedModal();
                loadData();
            }
        }
        setIsLoading(false);
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        if (selectedRole === null) return true;
        return false;
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-general-domain">
            <Modal.Header closeButton>
                <Modal.Title>Активация пользователя</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="CreateRK">
                    <Row className="d-flex flex-column">
                        <Col xs={12}>
                            <SelectSearch
                                onChange={handleChangeSelect}
                                title={'Выбор роли'}
                                options={roles}
                                itemLabel={'name'}
                                itemValue={'id'}
                                default={selectedRole}
                            />
                        </Col>
                    </Row>
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            variant="primary"
                            onClick={connectGeneralDomain}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Активировать'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalActivatedUser;
