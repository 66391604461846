import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import './styles.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Stats } from '../../../assets/images/stats.svg';
import { ReactComponent as Delete } from '../../../assets/images/deleteIcon.svg';
import { vaultService } from '../../../core/services/vault-service';
import { changePagesStats, deleteStatsDomain, getDomainStats } from '../actions';
import { useNavigate } from 'react-router-dom';
import UIFormInput from '../../../components/UI/UIFormInput';
import SelectMulti from '../../../components/UI/SelectMulti';
import ModalAccept from '../../../components/Modals/ModalAccept';

const ListStatsDomain = () => {
    const [search, setSearch] = useState('');
    const [profileStatuses, setProfileStatuses] = useState([]);
    const domains = useSelector((state) => state.buyerReducer.domainsStats);
    const page = useSelector((state) => state.buyerReducer.pageStats);
    const timeoutRef = useRef(null);
    const [isShowAccept, setIsShowAccept] = useState(false);
    const [currentDomain,setCurrentDomain] = useState(null);
    const [isLoadingDelete,setIsLoadingDelete] = useState(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesStats(currentPage));
        await dispatch(getDomainStats(userId, page, search, profileStatuses));
    };

    const loadData = async () => {
        await dispatch(getDomainStats(userId, page, search, profileStatuses));
    };

    useEffect(() => {
        loadData();
    }, [profileStatuses]);

    const handleClickStats = (domain) => {
        navigate(`/statistic/${domain}`);
    };

    const openModal = (domain) => {
        setIsShowAccept(true);
        setCurrentDomain(domain)
    }

    const closeModal = () => {
        setIsShowAccept(false);
        setCurrentDomain(null);
    }

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setSearch(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomainStats(userId, page, value, profileStatuses));
        }, 300);
    };


    const factoryColorStatusStats = (status) => {
        switch (status) {
            case 'Processing':
                return 'text-warning text-bold';
            case 'Success':
                return 'text-success text-bold';
            case 'Error':
                return 'text-danger text-bold';
            default:
                return '-';
        }
    };

    const factoryColorStatus = (status) => {
        switch (status) {
            case 1:
                return 'text-warning text-bold';
            case 2:
                return 'text-success text-bold';
            case 3:
                return 'text-danger text-bold';
            default:
                return '-';
        }
    };

    const factoryNameStatus = (status) => {
        switch (status) {
            case 1:
                return 'Paused';
            case 2:
                return 'Active';
            case 3:
                return 'Ban';
            default:
                return '-';
        }
    };

    const optionsStatuses = [
        { label: 'Paused', value: 1 },
        { label: 'Active', value: 2 },
        { label: 'Ban', value: 3 },
    ];

    const handleChangeSelect = (status) => {
        setProfileStatuses(status);
    };

    const handleDeleteDomain = async () => {
        setIsLoadingDelete(currentDomain);
        await dispatch(deleteStatsDomain(userId, currentDomain));
        await loadData()
        closeModal()
        setIsLoadingDelete(null);
        setCurrentDomain(null);
    }

    return (
        <div className="ListOrder">
            <div className="d-flex flex-column p-0">
                <Row style={{ marginBottom: '15px' }} className="border-bottom pb-3">
                    <Col xs={3} className="p-0">
                        <SelectMulti
                            onChange={(e) => handleChangeSelect(e)}
                            title={''}
                            options={optionsStatuses}
                            backspaceRemovesValue={false}
                            itemLabel={'label'}
                            itemValue={'value'}
                            placeholder="Profile status"
                            values={profileStatuses}
                        />
                    </Col>
                    <Col xs={3} className="p-0">
                        <UIFormInput
                            label=""
                            onChange={handleChangeInput}
                            type="text"
                            id="search"
                            placeholder="Search domain"
                            value={search}
                        />
                    </Col>
                </Row>
                <Row className="border-bottom pb-3 text-bold position-relative w-100">
                    <Col className="ListOrder-item_header">Name domain</Col>
                    <Col className="ListOrder-item_header">Status</Col>
                    <Col className="ListOrder-item_header">Account Status</Col>
                    <Col className="ListOrder-item_header">Uuid</Col>
                    <Col className="ListOrder-item_header"></Col>
                </Row>
                {domains.domains.map((item) => (
                    <Row className={`border-bottom pb-3 pt-4 d-flex list`} key={item._id}>
                        <Col className="ListOrder-item">{item.domain}</Col>
                        <Col  className={`ListOrder-item ${factoryColorStatusStats(item?.profile?.status)}`}>{item.profile.status}</Col>
                        <Col className={`ListOrder-item ${factoryColorStatus(item?.profile_status)}`}>
                            {factoryNameStatus(item?.profile_status)}
                        </Col>
                        <Col className="ListOrder-item">{item.uuid}</Col>
                        <Col className="ListOrder-item justify-content-end ml-auto gap-3">
                            <Stats className="icon-stats" onClick={() => handleClickStats(item.domain)} />
                            <Delete className="icon-stats" onClick={() => openModal(item.domain)}/>
                        </Col>
                    </Row>
                ))}
            </div>
            <div className="pagination">
                {domains.total > 15 && (
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={domains.total / 15}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={changePage}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page - 1}
                    />
                )}
            </div>
            {isShowAccept && (
                <ModalAccept
                    isLoading={isLoadingDelete === currentDomain}
                    show={isShowAccept}
                    title={`Вы точно хотите удалить ${currentDomain} ?`}
                    closeModal={closeModal}
                    regenerate={handleDeleteDomain}
                />
            )}
        </div>
    );
};

export default ListStatsDomain;
