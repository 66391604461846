export const getCompanyOptions = (statsForGraph) => {
    if (!statsForGraph) return [];

    const companies = statsForGraph.flatMap((item) =>
        item.companies.map((company) => ({
            label: company.name,
            value: company.name,
        })),
    );

    return [...new Set(companies.map((c) => JSON.stringify(c)))].map((c) => JSON.parse(c));
};

export const getGroupOptions = (statsForGraph) => {
    if (!statsForGraph) return [];

    const groups = statsForGraph.flatMap((item) =>
        item.companies.flatMap((company) =>
            company.adGroups.map((group) => ({
                label: group.name,
                value: group.name,
            })),
        ),
    );

    return [...new Set(groups.map((g) => JSON.stringify(g)))].map((g) => JSON.parse(g));
};

export const getAdvertisementOptions = (statsForGraph) => {
    if (!statsForGraph) return [];

    const advertisements = statsForGraph.flatMap((item) =>
        item.companies.flatMap((company) =>
            company.adGroups.flatMap((group) =>
                group.ads.map((ad) => ({
                    label: ad.id,
                    value: ad.id,
                })),
            ),
        ),
    );

    return [...new Set(advertisements.map((ad) => JSON.stringify(ad)))].map((ad) => JSON.parse(ad));
};
