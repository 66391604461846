import { Col, Row } from 'react-bootstrap';
import './AccountList.scss';
import Button from '../../../components/UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { changePagesAccount, disableAccount, getAccountList } from '../actions';
import ModalAccept from '../../../components/Modals/ModalAccept';

const AccountList = ({ handleClickButtonPending }) => {
    const { accountList, pageAccount } = useSelector((state) => state.aggregatorReducer);
    const [isLoading, setIsLoading] = useState(null);
    const [isShowAcceptModal, setIsShowAcceptModal] = useState(false);
    const [disabledAccount, setDisabledAccount] = useState(null);

    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getAccountList(pageAccount));
    };

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesAccount(currentPage));
        await dispatch(getAccountList(currentPage));
    };

    const handleOpenAcceptModal = (item) => {
        setDisabledAccount(item);
        setIsShowAcceptModal(!isShowAcceptModal);
    };

    const handleCloseAcceptModal = () => {
        setDisabledAccount(null);
        setIsShowAcceptModal(!isShowAcceptModal);
    };

    const handleClickButton = async () => {
        setIsLoading(true);
        await dispatch(disableAccount(disabledAccount?.id));
        await loadData();
        handleCloseAcceptModal();
        setDisabledAccount(null);
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="ListDomain">
            <div className="d-flex flex-column">
                <Row className="border-bottom pb-3 text-bold position-relative">
                    <Col className="ListDomain-item_header">Email</Col>
                    <Col className="ListDomain-item_header">Status</Col>
                    <Col className="ListDomain-item_header"></Col>
                    <Col className="ListDomain-item_header"></Col>
                </Row>
                {accountList.data.map((item) => (
                    <Row className={`border-bottom pb-3 pt-4 d-flex`} key={item.id}>
                        <Col className="ListDomain-item">{item.email}</Col>
                        <Col className="ListDomain-item">{item.status}</Col>
                        <Col className="ListDomain-item" style={{ maxWidth: '110px', marginLeft: 'auto' }}>
                            {item.status.includes('pending') && (
                                <Button
                                    onClick={() => handleClickButtonPending(item)}
                                    blue
                                    isLoading={isLoading === item.id}
                                    title="Подтвердить"
                                />
                            )}
                        </Col>
                        {!item.status.includes('disabled') && !item.status.includes('pending') && (
                            <Col className="ListDomain-item" style={{ maxWidth: '110px' }}>
                                <Button onClick={() => handleOpenAcceptModal(item)} blue title="Отключить" />
                            </Col>
                        )}
                    </Row>
                ))}
            </div>
            <div className="pagination">
                {accountList.meta?.total > accountList.meta?.per_page && (
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={accountList.meta?.total / accountList.meta?.per_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={changePage}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={pageAccount - 1}
                    />
                )}
            </div>
            {isShowAcceptModal && (
                <ModalAccept
                    regenerate={handleClickButton}
                    title="Вы точно хотите отключить этот акаунт ?"
                    closeModal={handleCloseAcceptModal}
                    show={isShowAcceptModal}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
};

export default AccountList;
