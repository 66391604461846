import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { changePages, getBuyers } from '../actions';
import { useEffect } from 'react';
import Button from '../../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/helpers/routes';

const ListBuyer = () => {
    const buyers = useSelector((state) => state.webMasterReducer.buyers);
    const page = useSelector((state) => state.webMasterReducer.page);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getBuyers(currentPage));
    };

    const loadData = async () => {
        dispatch(getBuyers(page));
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickButton = (id) => {
        const link = ROUTES.listDomainBuyer.link.replace(':userId', id);
        navigate(link);
    };

    return (
        <div className="MyDomain">
            <div className="d-flex flex-column">
                {buyers.data.map((item) => (
                    <Row xs={9} className={`border-bottom pb-3 pt-4 align-items-center`} key={item.id}>
                        <Col className="buyer_item">{item.name}</Col>
                        <Col xs={1} className="buyer_button pb-1">
                            <Button onClick={() => handleClickButton(item.id)} blue title="Открыть" />
                        </Col>
                    </Row>
                ))}
            </div>
            <div className="pagination">
                {buyers.meta?.total > buyers.meta?.per_page && (
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={buyers.meta?.total / buyers.meta?.per_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={changePage}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page - 1}
                    />
                )}
            </div>
        </div>
    );
};

export default ListBuyer;
