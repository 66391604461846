import { useDispatch, useSelector } from 'react-redux';
import UICardContainer from '../../../components/UI/UICardContainer';
import './styles.scss';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import { changePages, deactivatedRole, deactivatedUser, getRoles, getUsers } from '../actions';
import { useEffect, useState } from 'react';
import Button from '../../../components/UI/Button';
import ModalActivatedUser from '../../../components/Modals/ModalActivatedUser';

const ListUsers = () => {
    const users = useSelector((state) => state.adminReducer.users);
    const page = useSelector((state) => state.adminReducer.page);
    const roles = useSelector((state) => state.adminReducer.roles);

    const [isShow, setIsShow] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getUsers(currentPage));
    };

    const loadData = async () => {
        await dispatch(getUsers(page));
        await dispatch(getRoles());
    };

    useEffect(() => {
        loadData();
    }, []);

    const openModal = (id) => {
        setIsShow(true);
        setActiveUser(id);
    };

    const closeModal = () => {
        setIsShow(false);
        setActiveUser(null);
    };

    const handleClickDeactivateUser = async (user) => {
        setIsLoading(true);
        const role = roles.filter((item) => user.roles.some((el) => item.name === el));

        await role.map(async (item) => {
            await dispatch(deactivatedRole(user.id, item.id));
        });

        await dispatch(deactivatedUser(user.id));
        loadData();
        setIsLoading(false);
    };

    return (
        <UICardContainer title="Список пользователей">
            <div className="d-flex flex-column">
                <Row xs={4} className="border-bottom pb-3 text-bold justify-content-start">
                    <Col className="item_header_user">Name</Col>
                    <Col className="item_header_user">Email</Col>
                    <Col className="item_header_user">Role</Col>
                </Row>

                {users.data.map((item) => (
                    <Row xs={4} className={`border-bottom pb-3 pt-4`} key={item.id}>
                        <Col className="item_user">{item.name}</Col>
                        <Col className="item_user">{item.email}</Col>
                        <Col className="item_user">{item.roles[item.roles.length - 1]}</Col>
                        {item.status === 'active' ? (
                            <Col className="item_button">
                                <Button
                                    title="Деактивировать"
                                    isLoading={isLoading}
                                    onClick={() => handleClickDeactivateUser(item)}
                                />
                            </Col>
                        ) : (
                            <Col className="item_button">
                                <Button blue title="Активировать" onClick={() => openModal(item)} />
                            </Col>
                        )}
                    </Row>
                ))}
            </div>
            <div className="pagination">
                {users.meta?.total > users.meta?.per_page && (
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={users.meta?.total / users.meta?.per_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={changePage}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page - 1}
                    />
                )}
            </div>
            {isShow && <ModalActivatedUser show={isShow} closeModal={closeModal} loadData={loadData} user={activeUser} />}
        </UICardContainer>
    );
};

export default ListUsers;
