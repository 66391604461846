import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import './styles.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopic } from '../../SysAdmin/action';
import { changeOrderPages, getOrders } from '../../WebMaster/actions';
import { vaultService } from '../../../core/services/vault-service';

const ListOrder = () => {
    const orders = useSelector((state) => state.webMasterReducer.orders);
    const page = useSelector((state) => state.webMasterReducer.pageOrder);
    const topics = useSelector((state) => state.serverReducer.topics);

    const dispatch = useDispatch();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changeOrderPages(currentPage));
        await dispatch(getOrders(currentPage, userId));
    };

    const loadData = async () => {
        await dispatch(getOrders(page, userId));
        await dispatch(getTopic());
    };

    const showNameTopic = (id) => {
        const topic = topics.find((item) => item.id === id);
        if (topic) {
            return topic.topic;
        }

        return '-';
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <div className="ListOrder">
                <div className="d-flex flex-column">
                    <Row className="border-bottom pb-3 text-bold">
                        <Col className="ListOrder-item_header">ID</Col>
                        <Col className="ListOrder-item_header">Name domain</Col>
                        <Col className="ListOrder-item_header">Topic</Col>
                        <Col className="ListOrder-item_header">Comment</Col>
                        <Col className="ListOrder-item_header">Status</Col>
                    </Row>
                    {orders.data.map((item) => (
                        <Row className={`border-bottom pb-3 pt-4 d-flex list`} key={item.id}>
                            <Col className="ListOrder-item">{item.id}</Col>
                            <Col className="ListOrder-item">{item.domain_name}</Col>
                            <Col className="ListOrder-item">{showNameTopic(item.topic_id)}</Col>
                            <Col className="ListOrder-item">{item.desc}</Col>
                            <Col className="ListOrder-item">{item.status}</Col>
                        </Row>
                    ))}
                </div>
                <div className="pagination">
                    {orders.meta?.total > orders.meta?.per_page && (
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={orders.meta?.total / orders.meta?.per_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={1}
                            onPageChange={changePage}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={page - 1}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListOrder;
