import { NotificationManager } from 'react-notifications';
import { helperError } from '../../core/helpers/helperError';
import { API } from '../../core/api';

export const putOutCloak = (domainId, options) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domainId}/cloak/rules`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getBlocks = (domainId) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domainId}/blocks/rules`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editBlocks = (options, domainId) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domainId}/blocks/rules`, options);

        NotificationManager.success(`Success`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getOutCloak = (domainId) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domainId}/cloak/rules`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getLandings = (domainId) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domainId}/defaults`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const saveLandings = (options, domainId) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domainId}/defaults`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getFilters = (domain) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domain}/rules`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getDefaultRule = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/rules/default`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const updateDefaultRule = (options) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/rules/default`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getHeaders = (key) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/static/get/${key}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getStaticLanding = (domain) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domain}/result/enum`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editFilterRules = (options, domain) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domain}/rules`, options);

        NotificationManager.success(`Success`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getFiltersList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filters/filters/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getGroupLanding = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/groups/landings`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createNewLand = (options) => async () => {
    try {
        const response = await API.post(
            `microservices/filtering/api/v1/binom/1/send/public/api/v1/landing/not_integrated`,
            options,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createNewOffer = (options) => async () => {
    try {
        const response = await API.post(`microservices/filtering/api/v1/binom/1/send/public/api/v1/offer`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getSystemTokens = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/system/tokens`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getLanguageList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/language/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getCountryList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/country/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getAffList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/affiliate_network/list/all`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getCurrencyList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/currency/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getLander = () => async () => {
    try {
        const response = await API.get(
            `microservices/filtering/api/v1/binom/1/send/public/api/v1/landing/list/filtered??limit=50&offset=0&sortColumn=id&sortType=DESC`,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getOffer = () => async () => {
    try {
        const response = await API.get(
            `microservices/filtering/api/v1/binom/1/send/public/api/v1/offer/alternative/all?limit=50&offset=0&sortColumn=id&sortType=DESC`,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};
