import { Button, Card, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadWhitePage, getWhitePage } from '../../../components/GeneratorWhitePage/actions';
import { ROUTES } from '../../../core/helpers/routes';
import ProgressBar from '../../../components/UI/ProgressBar';
import { vaultService } from '../../../core/services/vault-service';

const ShowWhitePage = () => {
    const [whitePage, setWhitePage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    let timer = null;

    const { id } = useParams();

    const dispatch = useDispatch();

    const loadData = async () => {
        const data = await dispatch(getWhitePage(id));
        if (data) {
            setWhitePage(data);
        }
    };

    const runUpdateCheckbox = () => {
        const INTERVAL = 15000;
        timer = setTimeout(async () => {
            const data = await dispatch(getWhitePage(id));

            if (data) {
                setWhitePage(data);
                if (typeof data.percent_generation === 'string' && data.percent_generation === '100%') {
                    runUpdateCheckbox();
                    return;
                }
                if (typeof data.percent_generation === 'string' && data.percent_generation !== '100%') {
                    handleClickLink();
                    return;
                }
                clearTimeout(timer);
            }
        }, INTERVAL);
    };

    useEffect(() => {
        loadData();
        runUpdateCheckbox();

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const clickShowFull = () => {
        window.open(`http://13tools.com:8443/api/white-pages/${id}/preview-with-editor`, '_blank');
    };

    const show = () => {
        if (!whitePage) return null;
        if (typeof whitePage.percent_generation === 'string' && whitePage.percent_generation !== '100%')
            return <ProgressBar progress={whitePage.percent_generation} />;
        return (
            <div className="">
                <iframe
                    src={`http://13tools.com:8443/api/white-pages/${id}/preview-with-editor`}
                    width="100%"
                    height="700px"
                    frameBorder="0"
                ></iframe>
            </div>
        );
    };

    const service = vaultService();
    const role = service.getItem('role');

    const navigate = useNavigate();

    const handleClickLink = () => {
        switch (role) {
            case 'sysadmin':
                navigate(ROUTES.sysAdminPanel.link);
                break;

            case 'helper':
                navigate(ROUTES.helperSysAdminPanel.link);
                break;

            case 'farmer':
                navigate(ROUTES.panelFarmer.link);
                break;

            case 'buyer':
                navigate(ROUTES.panelBayer.link);
                break;
            case 'webmaster':
                navigate(-1);
                break;

            default:
                break;
        }
    };

    const download = async () => {
        setIsLoading(true);
        const resp = await dispatch(downloadWhitePage(id));

        if (resp?.data) {
            const blob = new Blob([resp.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${whitePage?.name || 'downloaded_file'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        setIsLoading(false);
    };

    return (
        <Card className="Show">
            <span onClick={() => handleClickLink()} className="Back">
                Назад
            </span>
            <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                        <h5>{whitePage?.name}</h5>
                        <div className="d-flex gap-2 align-items-center">
                            <Button onClick={download} variant="primary" style={{ width: '100px' }}>
                                {isLoading ? <Spinner animation="border" size="sm" /> : 'Download'}
                            </Button>
                            <Button onClick={clickShowFull} variant="primary">
                                Show full
                            </Button>
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="mt-2 p-0">{show()}</Card.Body>
        </Card>
    );
};

export default ShowWhitePage;
