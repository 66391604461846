/* eslint-disable */

import { SET_USERS, CHANGE_PAGE_USERS, SET_ROLES } from './types';

const initialState = {
    users: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    page: 1,
    roles: [],
};

const adminReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_USERS:
            return {
                ...state,
                users: {
                    ...payload,
                    data: payload.data.filter((item) => !item.roles.find((el) => el === 'superadmin')),
                },
            };
        case CHANGE_PAGE_USERS:
            return { ...state, page: payload };
        case SET_ROLES:
            return {
                ...state,
                roles: payload.filter((item) => item.name !== 'user'),
            };
        default:
            return state;
    }
};

export default adminReducer;
