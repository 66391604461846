import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import { CHANGE_PAGE, CHANGE_PAGE_WARM, CHANGE_TAB_FARMER, SET_DOMAIN_FARMER, SET_DOMAIN_FARMER_WARM } from './types';
import { vaultService } from '../../core/services/vault-service';
import { helperError } from '../../core/helpers/helperError';

const service = vaultService();
const user = service.getItem('user');

export const getDomeinsFarmer = (page, name) => async (dispatch) => {
    try {
        const response = await API.get(
            `/microservices/servers-managment/api/v1/domains/?filter[status]=new&page=${page}&filter[name]=${name}`,
        );
        dispatch({ type: SET_DOMAIN_FARMER, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getDomeinsFarmerWarm = (page, name) => async (dispatch) => {
    try {
        const response = await API.get(
            `/microservices/servers-managment/api/v1/domains/?filter[status]=warm&page=${page}&filter[user_id]=${user?.id}&filter[name]=${name}`,
        );
        dispatch({ type: SET_DOMAIN_FARMER_WARM, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const changePages = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
};

export const changeTab = (tab) => async (dispatch) => {
    dispatch({ type: CHANGE_TAB_FARMER, payload: tab });
};

export const setWhitePageDomain = (domain) => async () => {
    try {
        await API.post(`/microservices/servers-managment/api/v1/domains/${domain}/download`);
        NotificationManager.success(`Заливка на сервер началась, это может занять до 10 минут`, '', 5000);
    } catch (error) {
        helperError(error);
    }
};

export const changePagesWarm = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE_WARM, payload: page });
};

export const changeStatusDomainWarm =
    (options, domain, success = false) =>
    async () => {
        try {
            const resp = await API.put(`/microservices/servers-managment/api/v1/domains/${domain}`, options);
            if (!success) {
                NotificationManager.success(`Успешно`, '', 3500);
            }

            return resp;
        } catch (error) {
            helperError(error);
        }
    };
