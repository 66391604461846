/* eslint-disable */

import { SET_SERVER, SET_TOPIC, CHANGE_PAGE } from './types';

const initialState = {
    servers: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    page: 1,
    topics: [],
};

const serverReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SERVER:
            return { ...state, servers: payload };
        case CHANGE_PAGE:
            return { ...state, page: payload };
        case SET_TOPIC:
            return { ...state, topics: payload };
        default:
            return state;
    }
};

export default serverReducer;
