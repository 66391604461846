import React, { useState } from 'react';
import Select from 'react-select';

const SelectMulti = (props) => {
    const {
        onChange,
        default: defaultOptions = [],
        className = '',
        isClearable = true,
        backspaceRemovesValue = true,
        isDisabled = false,
        itemLabel,
        itemValue,
        placeholder,
        id,
        options,
        title,
        classNameTitle,
        values,
        selectedNewValue = false,
    } = props;

    const [selectedOptions, setSelectedOptions] = useState(defaultOptions);
    const [newValue, setNewValue] = useState('');

    const getOptions = () => {
        const stringOptions = options.map((item) => ({
            label: item[itemLabel],
            value: item[itemValue],
        }));
        return stringOptions;
    };

    const handleOnChange = (selectedValues) => {
        setSelectedOptions(selectedValues.map((option) => option.value));
        onChange(selectedValues.map((option) => option.value));
    };

    const handleChangeInput = (inputValue) => {
        setNewValue(inputValue);
    };

    const handleClick = async (e) => {
        if (e.key === 'Enter' && newValue !== '') {
            if (selectedNewValue) {
                const newOption = { label: newValue, value: newValue };

                if (!values.find((item) => item === newOption.value)) {
                    setSelectedOptions([...selectedOptions, newOption.value]);
                    setNewValue('');

                    onChange([...selectedOptions, newOption.value]);
                } else {
                    setNewValue('');
                }
                return;
            }
            if (!selectedNewValue && selectedOptions.find((item) => item === newValue)) {
                setNewValue('');
                return;
            }
            setNewValue('');
        }
    };

    return (
        <div className={`d-flex flex-column p-1 ${className}`}>
            <span className={`pb-1 font-weight-bold ${classNameTitle}`}>{title}</span>
            <Select
                className={'select ' + className}
                value={values.length !== 0 ? options.filter((item) => values.some((el) => item.value === el)) : []}
                onChange={handleOnChange}
                onKeyDown={handleClick}
                onInputChange={handleChangeInput}
                isClearable={isClearable}
                backspaceRemovesValue={backspaceRemovesValue}
                options={getOptions()}
                placeholder={placeholder}
                id={id}
                isDisabled={isDisabled}
                inputValue={newValue}
                isMulti
            />
        </div>
    );
};

export default SelectMulti;
