import React from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import { vaultService } from '../../../core/services/vault-service';
import { changeTab } from '../actions';
import ListBuyer from '../ListBuyer';
import ListOrder from '../ListOrder';

const PanelWebMaster = () => {
    const { tab } = useSelector((state) => state.webMasterReducer);

    const service = vaultService();

    const storageTab = service.getItem('tabWebMaster');

    const activeTab = storageTab ? storageTab : tab;

    const dispatch = useDispatch();

    const handleChangeTabs = (tab) => {
        dispatch(changeTab(tab));
        service.setItem('tabWebMaster', tab);
    };

    return (
        <>
            <Card className="Buyer">
                <Card.Header>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="Buyer-header">
                            <span className={activeTab === 1 ? 'active-tab' : ''} onClick={() => handleChangeTabs(1)}>
                                Список Buyers
                            </span>
                            <span className={activeTab === 2 ? 'active-tab' : ''} onClick={() => handleChangeTabs(2)}>
                                Список заказов
                            </span>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>{activeTab === 1 ? <ListBuyer /> : <ListOrder />}</Card.Body>
            </Card>
        </>
    );
};

export default PanelWebMaster;
