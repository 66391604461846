import { Link, useLocation } from 'react-router-dom';
import './styles.scss';
import { ROUTES } from '../../core/helpers/routes';
import { useState } from 'react';
import { ReactComponent as Hosting } from '../../assets/images/hosting.svg';

const Menu = () => {
    const { pathname } = useLocation();
    const [closeMenu, setCloseMenu] = useState(false);

    const activePage = (page) => {
        if (pathname.includes(page)) return true;
        return false;
    };

    const menuRoles = [
        { title: 'Панель sysadmin', link: ROUTES.sysAdminPanel.path },
        {
            title: 'Помощник sysadmin',
            link: ROUTES.helperSysAdminPanel.path,
        },
        {
            title: 'Панель фармера',
            link: ROUTES.panelFarmer.path,
        },
        {
            title: 'Панель Байера',
            link: ROUTES.panelBayer.path,
        },
    ];

    return (
        <div
            className={`wrapper_menu sidebar-closed hover-main-sidebar elevation-4 ${
                false === false ? (!closeMenu ? 'sidebar-closed' : 'sidebar-closed-no-hover') : 'sidebar-opened'
            } sideBar`}
        >
            {/* <span className="item_text_menu">Навигация</span> */}
            {menuRoles.map((item) => (
                <Link style={{ textDecoration: 'none' }} to={item.link}>
                    <div className={activePage('hosting') ? 'item_menu_active' : 'item_menu'}>
                        <Hosting />
                        <span>{item.title}</span>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Menu;
