import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

// components
import App from './App';
// store
import { store } from './store';
// styles
import './index.css';
import { NotificationContainer } from 'react-notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
        <NotificationContainer />
    </Provider>,
);

reportWebVitals();
