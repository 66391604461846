import React from 'react';
import { AiFillEdit } from 'react-icons/ai';
import './styles.scss';

const EditButton = ({ onClick, disabled = false }) => {
    return (
        <button type="button" onClick={onClick} disabled={disabled} className="mr-2 edit-btn">
            <AiFillEdit className="mr-2 edit-btn-icon" id="test-edit-button" />
        </button>
    );
};

export default EditButton;
