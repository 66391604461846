import './ModalUploadWhitePage.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Row, Spinner } from 'react-bootstrap';
import { getWhitePageByUser, uploadWhitePage, uploadWhitePageUuid } from '../../../pages/Bayer/actions';
import SelectSearch from '../../UI/Select';
import { vaultService } from '../../../core/services/vault-service';

const ModalUploadWhitePage = ({ closeModal, show, loadData, domain }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [whitePages, setWhitePages] = useState([]);
    const [step, setStep] = useState(1);
    const fileInputRef = useRef(null);
    const [selectedWhitePage, setSelectedWhitePage] = useState(null);
    const service = vaultService();
    const userId = service.getItem('user').id;
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);

    const dispatch = useDispatch();

    const loadWhitePages = async () => {
        const resp = await dispatch(getWhitePageByUser(userId));
        if (resp) {
            const options = resp.data.map((item) => ({ label: item.name, value: item.id }));
            setWhitePages(options);
        }
    };

    const closedModal = () => {
        closeModal();
    };

    const handleChangeStep = (step) => {
        setStep(step);
    };

    useEffect(() => {
        loadWhitePages();
    }, []);

    const handleFileChange = async (event) => {
        setIsLoadingUpload(true);
        const file = event.target.files[0];
        const resp = await dispatch(uploadWhitePage(file, domain.id));
        if (resp) {
            closedModal();
        }
        setIsLoadingUpload(false);
    };

    const handleClickUploadWhitePage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleChangeSelect = (e) => {
        setSelectedWhitePage(e);
    };

    const handleClickUploadWhitePageInGenerator = async () => {
        setIsLoading(true);
        const resp = await dispatch(uploadWhitePageUuid(domain?.id, selectedWhitePage));
        if (resp) {
            closedModal();
        }
        setIsLoading(false);
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-create-account">
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center gap-2 justify-content-between w-100">
                    Загрузка White Page
                </Modal.Title>
            </Modal.Header>
            <div className="d-flex flex-column gap-3 p-4">
                <Row className="ModalUploadWhitePage">
                    {step === 1 && (
                        <div className="ModalUploadWhitePage-first-step">
                            <>
                                {!isLoadingUpload && (
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e)}
                                        accept=".zip,.rar"
                                    />
                                )}
                                <Button variant="primary" onClick={handleClickUploadWhitePage}>
                                    {isLoadingUpload ? <Spinner size="sm" animation="border" /> : 'Загрузить архив'}
                                </Button>
                            </>

                            <Button variant="primary" onClick={() => handleChangeStep(2)}>
                                Выбор White Page
                            </Button>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="ModalUploadWhitePage-second-step">
                            <SelectSearch
                                onChange={handleChangeSelect}
                                options={whitePages}
                                placeholder="White Page"
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={selectedWhitePage}
                            />
                        </div>
                    )}
                </Row>
                {step === 2 && (
                    <div className="ModalUploadWhitePage-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={() => handleChangeStep(1)}
                        >
                            Назад
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            variant="primary"
                            onClick={handleClickUploadWhitePageInGenerator}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Загрузить'}
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ModalUploadWhitePage;
