import { API } from '../../core/api';
import {
    CHANGE_MASTER_PAGE,
    SET_BUYERS,
    CHANGE_TAB_MASTER,
    CHANGE_ORDER_PAGE,
    SET_ORDERS,
    DELETE_ORDER,
    UPDATE_ORDER,
} from './types';
import { SET_DOMAIN_BUYER_RESERVED } from '../Bayer/types';
import { helperError } from '../../core/helpers/helperError';

export const getBuyers = (page) => async (dispatch) => {
    try {
        const resp = await API.get(`/admin/users?filter[roles]=buyer&page=${page}`);

        dispatch({ type: SET_BUYERS, payload: resp.data });
    } catch (error) {
        helperError(error);
    }
};

export const getOrders =
    (page, userId = '', status = '') =>
    async (dispatch) => {
        try {
            const resp = await API.get(
                `/microservices/servers-managment/api/v1/orders?filter[user_id]=${userId ?? ''}&page=${page}&filter[status]=${status ?? ''}`,
            );

            dispatch({ type: SET_ORDERS, payload: resp.data });
        } catch (error) {
            helperError(error);
        }
    };

export const updateOrder = (id, options) => async (dispatch) => {
    try {
        const resp = await API.put(`/microservices/servers-managment/api/v1/orders/${id}`, options);

        dispatch({ type: UPDATE_ORDER, payload: resp.data });
    } catch (error) {
        helperError(error);
    }
};

export const deleteOrder = (id) => async (dispatch) => {
    try {
        await API.delete(`/microservices/servers-managment/api/v1/orders/${id}`);

        dispatch({ type: DELETE_ORDER, payload: id });
    } catch (error) {
        helperError(error);
    }
};

export const getUser = (id) => async () => {
    try {
        const resp = await API.get(`/admin/users/${id}`);

        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getBinomCheck = (user) => async () => {
    try {
        const resp = await API.get(`/microservices/filtering/api/v0/user/${user}/binom/check`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const changeTab = (tab) => async (dispatch) => {
    dispatch({ type: CHANGE_TAB_MASTER, payload: tab });
};

export const changePages = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_MASTER_PAGE, payload: page });
};

export const changeOrderPages = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_ORDER_PAGE, payload: page });
};

export const getDomeinsBuyerReserved = (page, id, name) => async (dispatch) => {
    try {
        const response = await API.get(
            `/microservices/servers-managment/api/v1/domains/?filter[status]=reserved&page=${page}&filter[user_id]=${id}&filter[name]=${name}`,
        );
        dispatch({ type: SET_DOMAIN_BUYER_RESERVED, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};
