import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import UIFormInput from '../../UI/UIFormInput';
import SelectSearch from '../../UI/Select';
import { useEffect, useState } from 'react';
import { createDomain, editDomain, getServers, getServersAll, getTopic } from '../../../pages/SysAdmin/action';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrders } from '../../../pages/WebMaster/actions';

const ModalCreateDomain = ({ closeModal, show, loadDomain, edit }) => {
    const topics = useSelector((state) => state.serverReducer.topics);
    const orders = useSelector((state) => state.webMasterReducer.orders);
    const [allServer, setAllServer] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();

    const [build, setBuild] = useState({
        topic: null,
        name: '',
        registrar: '',
        server: null,
        expiration_date: null,
    });

    const dispatch = useDispatch();
    const closedModal = () => {
        closeModal();
        setBuild({
            topic: null,
            name: '',
            registrar: '',
            server: null,
        });
    };

    const handleChangeInput = (e) => {
        let value = e.target.value;
        const key = e.target.id;
        const regex = /[а-яА-ЯёЁ]/;
        if (!regex.test(value)) {
            setBuild({ ...build, [key]: value });
        }
    };

    const handleChangeSelect = (key, value) => {
        setBuild({ ...build, [key]: value });
    };

    const serversAll = async () => {
        let currentPage = 1;
        let allData = [];

        while (true) {
            const resp = await dispatch(getServersAll(currentPage));
            const newResp = resp.data.map((item) => ({
                ...item,
                label: `${item.host_provider_name} - ${item.ip}`,
            }));
            allData = [...allData, ...newResp];

            if (resp.meta.current_page === resp.meta.last_page) {
                break;
            }

            currentPage++;
        }

        setAllServer(allData);
    };

    const loadData = async () => {
        serversAll();
        await dispatch(getTopic());
        await dispatch(getServers());
        await dispatch(getOrders(1));

        if (edit) {
            setBuild({
                ...build,
                server: edit.server.id,
                registrar: edit.registrar,
                topic: edit['topic'].id,
                name: edit.name,
            });
            return;
        }
        if (id) {
            setBuild({ ...build, server: id });
        }
    };

    const handleCreateDomain = async () => {
        setIsLoading(true);
        const options = {
            topic_id: build.topic,
            name: build.name,
            registrar: build.registrar,
            domain_ip: build.domain_ip,
            domain_ip_alt: build.domain_ip_alt,
        };

        if (build?.order_id) {
            const order = orders.data.find((item) => item.id === build.order_id);
            if (order) {
                options.user_id = order.user.id;
                options.domain_data = {
                    order: { order_id: order.id },
                };
            }
        }

        if (edit) {
            options.server_id = build.server;
            options.status = edit.status;
            options.domain_data = edit.domain_data;

            const resp = await dispatch(editDomain(options, edit.id));
            if (resp) {
                closedModal();
                loadDomain();
            }
            setIsLoading(false);

            return;
        }
        const resp = await dispatch(createDomain(options, build.server));
        if (resp) {
            setBuild({
                name: '',
                registrar: '',
            });
            loadDomain();
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    const isDisableButton = () => {
        if (build.name.length === 0) return true;
        if (build.topic === null) return true;
        if (build.registrar.length === 0) return true;
        if (build.server === null) return true;
        return false;
    };

    const getOptionsBuyer = () => {
        if (!orders.data) return [];
        return orders.data
            .filter((item) => item.status === 'open' && item.user !== null)
            .map((item) => ({
                ...item,
                label: `${item.user?.name} - ${item.domain_name}`,
                value: item.id,
            }));
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-domain">
            <Modal.Header closeButton>
                <Modal.Title>{edit ? 'Изменить домен' : 'Добавить домен'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="CreateDomain">
                    <Row>
                        <Col xs={4}>
                            <SelectSearch
                                onChange={(e) => handleChangeSelect('server', e)}
                                title={'Выбор сервера'}
                                options={allServer}
                                itemLabel={'label'}
                                itemValue={'id'}
                                default={build?.server}
                                value={build?.server}
                            />
                        </Col>
                        <Col xs={4}>
                            <SelectSearch
                                onChange={(e) => handleChangeSelect('topic', e)}
                                title={'Topic'}
                                options={topics}
                                itemLabel={'topic'}
                                itemValue={'id'}
                                default={build?.topic}
                                value={build?.topic}
                            />
                        </Col>
                        <Col xs={4}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="name"
                                label={'Name domain'}
                                value={build?.name ? build?.name : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="registrar"
                                label={'Registrar'}
                                value={build?.registrar ? build?.registrar : ''}
                            />
                        </Col>
                        <Col xs={4}>
                            <SelectSearch
                                onChange={(e) => handleChangeSelect('order_id', e)}
                                title={'Buyer'}
                                options={getOptionsBuyer()}
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={build?.order_id}
                                value={build?.order_id}
                            />
                        </Col>
                    </Row>
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            onClick={handleCreateDomain}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : edit ? 'Изменить' : 'Добавить'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreateDomain;
