import './styles.scss';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import SelectSearch from '../../UI/Select';
import { useState } from 'react';
import { typeScript } from '../../../helpers/data';
import UIFormInput from '../../UI/UIFormInput';
import { createPreset } from '../../../pages/SysAdmin/action';
import { useDispatch } from 'react-redux';

const ModalCreatePreset = ({ show, closeModal }) => {
    const [build, setBuild] = useState({
        type: null,
        link1: '',
        link2: '',
        name: '',
        general_domain_link: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleChangeSelect = (item, key) => {
        setBuild({ ...build, [key]: item });
    };

    const handleChangeInput = (e) => {
        const { value, id } = e.target;
        setBuild({ ...build, [id]: value });
    };

    const closedModal = () => {
        setBuild({
            type: null,
            link1: '',
            link2: '',
            name: '',
            general_domain_link: '',
        });
        closeModal();
    };

    const isDisableButton = () => {
        if (build.type === null) return true;
        if (build.name.length === 0) return true;
        if (build.link1.length === 0) return true;
        if (build.link2.length === 0) return true;
        if (build.general_domain_link.length === 0) return true;
        return false;
    };

    const handleClickCreatePreset = async () => {
        setIsLoading(true);
        const resp = await dispatch(createPreset(build));
        if (resp) {
            closedModal();
        }
        setIsLoading(false);
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-preset">
            <Modal.Header closeButton>
                <Modal.Title>Create preset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="Preset">
                    <Row>
                        <Col>
                            <SelectSearch
                                onChange={(e) => handleChangeSelect(e, 'type')}
                                title={'Выберите Type'}
                                options={typeScript}
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={build?.type}
                                value={build?.type}
                            />
                        </Col>
                        <Col>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="name"
                                label={'Название'}
                                value={build?.name ? build?.name : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="link1"
                                label={'Link 1'}
                                value={build?.link1 ? build?.link1 : ''}
                            />
                        </Col>
                        <Col>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="link2"
                                label={'Link 2'}
                                value={build?.link2 ? build?.link2 : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="general_domain_link"
                                label={'General domain link'}
                                value={build?.general_domain_link ? build?.general_domain_link : ''}
                            />
                        </Col>
                    </Row>
                    <div className="Preset-button">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            variant="primary"
                            onClick={handleClickCreatePreset}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Создать'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreatePreset;
