import { vaultService } from '../../core/services/vault-service';
import logo from '../../assets/images/logo.png';
import CustomDropdown from './Tooltip';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';

const Header = () => {
    const service = vaultService();
    const user = service.getItem('user');
    const role = service.getItem('role');

    const navigate = useNavigate();

    const handleClickLogo = () => {
        switch (role) {
            case 'sysadmin':
                navigate(ROUTES.sysAdminPanel.link);
                break;

            case 'helper':
                navigate(ROUTES.helperSysAdminPanel.link);
                break;

            case 'farmer':
                navigate(ROUTES.panelFarmer.link);
                break;

            case 'buyer':
                navigate(ROUTES.panelBayer.link);
                break;

            default:
                break;
        }
    };

    return (
        <div className="wrapper_header">
            <div className="header_title" onClick={handleClickLogo}>
                <img src={logo} alt="" />
            </div>
            <NotificationContainer />
            <div className="user">
                <span>{user?.name}</span>
                <CustomDropdown />
            </div>
        </div>
    );
};

export default Header;
