import { Button } from 'react-bootstrap';
import UICardContainer from '../../../components/UI/UICardContainer';
import './styles.scss';
import ModalCreateServer from '../../../components/Modals/ModalCreateServer';
import { useState } from 'react';
import ModalCreateDomain from '../../../components/Modals/ModalCreateDomain';
import { Tooltip } from 'react-tooltip';

const HelperSysAdmin = () => {
    const [isShowCreateServer, setIsShowCreateServer] = useState(false);
    const [isShowCreateDomain, setIsShowCreateDomain] = useState(false);

    const handleCloseOpenShowCreateServer = () => {
        setIsShowCreateServer(!isShowCreateServer);
    };
    const handleCloseOpenShowCreateDomain = () => {
        setIsShowCreateDomain(!isShowCreateDomain);
    };

    return (
        <UICardContainer
            title="Панель помощника системного администратора"
            titleButtonLink="Скрипт для первичной настройки"
            linkGit="https://git.13.tools/external/scripts"
        >
            <Tooltip
                id={`my-tooltip-1`}
                place="bottom"
                style={{
                    zIndex: '9999999999',
                    margin: 0,
                    padding: '10px',
                    paddingBottom: 0,
                    maxWidth: '300px',
                }}
            >
                <div className="info_norm_buyer-content">
                    <p>
                        Прежде, чем добавлять сервер, необходимо произвести его первичную настройку. А именно: - зайти на сервер;
                        - скопировать скрипт для первичной настройки; - вставить и инициализировать скрипт на сервере. Только
                        после этого следует "Добавить Server".
                    </p>
                </div>
            </Tooltip>
            <div className="Helper">
                <Button
                    onClick={handleCloseOpenShowCreateServer}
                    className="Helper-button"
                    variant="primary"
                    data-tooltip-id={`my-tooltip-1`}
                >
                    Добавить Server
                </Button>
                <Button onClick={handleCloseOpenShowCreateDomain} className="Helper-button" variant="primary">
                    Добавить Domain
                </Button>

                {isShowCreateServer && (
                    <ModalCreateServer
                        show={isShowCreateServer}
                        closeModal={handleCloseOpenShowCreateServer}
                        loadServer={() => {}}
                    />
                )}
                {isShowCreateDomain && (
                    <ModalCreateDomain
                        show={isShowCreateDomain}
                        loadDomain={() => {}}
                        edit={null}
                        closeModal={handleCloseOpenShowCreateDomain}
                    />
                )}
            </div>
        </UICardContainer>
    );
};

export default HelperSysAdmin;
