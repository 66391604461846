/* eslint-disable */
import { SET_ACCOUNTS, CHANGE_PAGE_ACCOUNT, CHANGE_PAGE_MESSAGE, SET_MESSAGE, CHANGE_TAB_AGGREGATOR } from './types';

const initialState = {
    accountList: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    messageList: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    pageAccount: 1,
    pageMessage: 1,
    tab: 1,
};

const aggregatorReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ACCOUNTS:
            return { ...state, accountList: payload };
        case SET_MESSAGE:
            return { ...state, messageList: payload };
        case CHANGE_PAGE_ACCOUNT:
            return { ...state, pageAccount: payload };
        case CHANGE_TAB_AGGREGATOR:
            return { ...state, tab: payload };
        case CHANGE_PAGE_MESSAGE:
            return { ...state, pageMessage: payload };
        default:
            return state;
    }
};

export default aggregatorReducer;
