import { useEffect, useState } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { ReactComponent as Edit } from '../../../assets/images/edit.svg';
import { changeActivatedService, getListServices, getServices } from './actions';
import ModalActivatedService from '../../Modals/ModalActivatedService';
import ModalCreateBinomService from '../../Modals/ModalCreateBinomService';
import { ReactComponent as Question } from '../../../assets/images/question.svg';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const Services = () => {
    const [activeServices, setActiveServices] = useState([]);
    const [service, setService] = useState(null);
    const [editService, setEditService] = useState(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowModalBinom, setIsShowModalBinom] = useState(false);
    const [listServices, setListServices] = useState([]);
    const dispatch = useDispatch();

    const { id } = useParams();

    const loadData = async () => {
        const usingService = await dispatch(getServices(id));
        const listService = await dispatch(getListServices());
        if (usingService) {
            setActiveServices(usingService.data);
        }
        if (listService) {
            setListServices(listService.data);
        }
    };

    const closeModal = () => {
        setService(null);
        setEditService(null);
        setIsShowModal(false);
    };

    const closeModalBinom = () => {
        setService(null);
        setEditService(null);
        setIsShowModalBinom(false);
    };

    const openModalBinom = (service) => {
        setService(service);
        setIsShowModalBinom(true);
    };

    const openModal = (service) => {
        setService(service);
        setIsShowModal(true);
    };

    const openModalEdit = (service) => {
        const item = findedActivatedService(service);
        setEditService(item);
        setIsShowModal(true);
    };

    const handleChangeCheckBox = async (service) => {
        const item = findedActivatedService(service);
        if (item) {
            const value = item.active === 1 ? 0 : 1;
            const options = {
                services: [{ id: item.id, active: value }],
            };
            const resp = await dispatch(changeActivatedService(options, id));
            if (resp) {
                const mapped = activeServices.map((item) => {
                    const element = resp.data.find((el) => el.id === item.id);
                    if (element) {
                        return element;
                    }
                    return item;
                });
                setActiveServices(mapped);
            }
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const showActivatedService = (service) => {
        const finded = activeServices.find((item) => item.service.id === service.id);
        if (finded) return true;
        return false;
    };

    const findedActivatedService = (service) => {
        const finded = activeServices.find((item) => item.service.id === service.id);
        if (finded) return finded;
        return null;
    };

    const showInfoServices = () => {
        return (
            <div className="info_norm_buyer">
                <Question className="question-icon" data-tooltip-id="question-tooltip-services" />
                <Tooltip
                    id="question-tooltip-services"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '350px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Activated — кнопка, отвечающая за активацию сервиса и вызывающая всплывающее окно для его подключения.
                            После активации она будет заменена переключателем, активировать\деактевировать ли уже подключенную
                            услугу на этом домене. Примечание: будьте внимательны при отключении клоак-сервисов на активных
                            доменах!
                        </p>

                        <p className="m-0 p-0 w-100">
                            Accounts — кнопка, отвечающая за подключение учетных записей в сторонних сервисах, появляется только в
                            том случае, если сервису необходимо подключить личный кабинет. Без подключения такого аккаунта вы не
                            сможете подключить сервис.
                        </p>

                        <p className="m-0 p-0 w-100">
                            Значок редактирования — появляется, если после подключения сервиса вы можете редактировать ранее
                            введенные данные.
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <div className="Services">
            <div className="Services-header">
                <h3>Services</h3>
                {showInfoServices()}
            </div>
            <div className="Services-container">
                <div className="Services-container-header">
                    <div className="Services-container-header-title">
                        <span>List Services</span>
                    </div>
                    <span></span>
                    <span>Active</span>
                </div>
                <div className="Services-container-content">
                    {listServices.map((item) => (
                        <div key={item.id} className="Services-container-content-wrapper">
                            <div className="Services-container-content-item">
                                <div className="Services-container-content-item-title">
                                    <div className="Services-container-content-item-title-stripe" />
                                    {item.name}
                                </div>
                                <div className="Services-container-content-item-weight"></div>
                                <div className="Services-container-content-item-icons">
                                    {showActivatedService(item) && item.can.includes('update') && (
                                        <Edit onClick={() => openModalEdit(item)} className="icon-edit" />
                                    )}
                                </div>
                                <div className="Services-container-content-item-active">
                                    <div className="form-check form-switch" style={{ display: 'flex', gap: '6px' }}>
                                        {item.can.includes('addAccount') && (
                                            <button
                                                onClick={() => openModalBinom(item)}
                                                style={{ height: '20px' }}
                                                disabled={item.active === 0}
                                                className="wrapper-button-added-path-button"
                                            >
                                                <span>Accounts</span>
                                            </button>
                                        )}
                                        {!showActivatedService(item) && item.active !== 0 && item.can.includes('create') && (
                                            <button
                                                onClick={() => openModal(item)}
                                                style={{ height: '20px' }}
                                                disabled={item.active === 0}
                                                className="wrapper-button-added-path-button"
                                            >
                                                <span>Activated</span>
                                            </button>
                                        )}
                                        {showActivatedService(item) && (
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={findedActivatedService(item).active === 1 ? true : false}
                                                onChange={() => handleChangeCheckBox(item)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isShowModalBinom && (
                <ModalCreateBinomService
                    closeModal={closeModalBinom}
                    show={isShowModalBinom}
                    service={service}
                    loadData={loadData}
                />
            )}
            {isShowModal && (
                <ModalActivatedService
                    show={isShowModal}
                    closeModal={closeModal}
                    service={service}
                    setService={setService}
                    loadData={loadData}
                    edit={editService}
                />
            )}
        </div>
    );
};

export default Services;
