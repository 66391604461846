import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { loginByEmail } from './actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import { NotificationContainer } from 'react-notifications';
import { loginRole } from '../../utils/roleLOgin';
import docCookies from '../../core/services/cookie';
import { vaultService } from '../../core/services/vault-service';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        setEmail(value);
    };

    const handleChangePassword = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const service = vaultService();

    const logOut = () => {
        docCookies.removeItem('token');
        service.removeItems('role');
        service.removeItems('user');
    };

    const submitClickHandler = async () => {
        setIsLoading(true);
        const resp = await dispatch(loginByEmail(email, password));
        if (typeof resp.data?.message === 'string') {
            setEmail('');
            setPassword('');
            logOut();
            setIsLoading(false);
            return;
        }
        if (typeof resp.data === 'string') {
            setEmail('');
            setPassword('');
            logOut();
            setIsLoading(false);
            return;
        }

        if (resp?.response?.status === 422) {
            setErrorLogin(true);
            setTimeout(() => {
                setErrorLogin(false);
            }, 3000);
        }

        if (resp?.data?.access_token) {
            const role = loginRole(resp?.data?.user?.roles[resp?.data?.user?.roles.length - 1]);
            if (role === 'error') {
                setEmail('');
                setPassword('');
                logOut();
                setIsLoading(false);
                return;
            }
            navigate(ROUTES.index.main);
            window.location.reload();
        }
        setIsLoading(false);
    };

    const clickRegister = () => {
        navigate(ROUTES.register.link);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitClickHandler();
        }
    };

    return (
        <div style={{ height: '100vh' }} className="d-flex flex-column justify-content-center align-items-center">
            <div className="ml-auto mr-auto">
                <div className="border-bottom pb-4">
                    <h1 className="text-silver">С возвращением.</h1>
                    <span className="text-bold">Пожалуйста, войдите в свою учетную запись.</span>
                </div>

                <div className="d-flex flex-column gap-4 mt-4 align-items-center justify-content-center border-bottom pb-4">
                    <div className="d-flex gap-4 mt-4 align-items-center justify-content-center">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Почта"
                            value={email}
                            style={{ width: '367px', height: '50px' }}
                            onChange={handleChangeEmail}
                            onKeyDown={handleKeyDown}
                        />
                        <input
                            className="form-control"
                            type="password"
                            placeholder="Пароль"
                            style={{ width: '367px', height: '50px' }}
                            value={password}
                            onChange={handleChangePassword}
                            onKeyDown={handleKeyDown}
                        />
                    </div>

                    {errorLogin && (
                        <div className="d-flex text-danger">
                            <span>Не правильный логин или пароль</span>
                        </div>
                    )}
                </div>
                <div className="mt-4 d-flex gap-4 align-items-center justify-content-end">
                    <div className="d-flex align-items-center gap-4">
                        <Button
                            style={{ height: '40px', width: '150px' }}
                            className="ml-auto"
                            variant="primary"
                            onClick={clickRegister}
                            type="submit"
                        >
                            Регистрация
                        </Button>
                        <Button
                            style={{ height: '40px', width: '150px' }}
                            className="ml-auto"
                            variant="primary"
                            onClick={submitClickHandler}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Войти'}
                        </Button>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Login;
