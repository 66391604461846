/* eslint-disable */

import {
    CHANGE_MASTER_PAGE,
    CHANGE_TAB_MASTER,
    SET_BUYERS,
    CHANGE_ORDER_PAGE,
    SET_ORDERS,
    DELETE_ORDER,
    UPDATE_ORDER,
} from './types';

const initialState = {
    orders: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    buyers: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    tab: 1,
    page: 1,
    pageOrder: 1,
};

const webMasterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_BUYERS:
            return { ...state, buyers: payload };
        case SET_ORDERS:
            return { ...state, orders: payload };
        case CHANGE_MASTER_PAGE:
            return { ...state, page: payload };
        case CHANGE_TAB_MASTER:
            return { ...state, tab: payload };
        case CHANGE_ORDER_PAGE:
            return { ...state, pageOrder: payload };
        case UPDATE_ORDER:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    data: state.orders.data.map((item) => {
                        if (item.id === payload.id) {
                            return payload;
                        }
                        return item;
                    }),
                },
            };

        case DELETE_ORDER:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    data: state.orders.data.filter((item) => item.id !== payload),
                },
            };
        default:
            return state;
    }
};

export default webMasterReducer;
