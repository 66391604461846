import { Modal } from 'react-bootstrap';
import './styles.scss';
import GeneratorWhitePage from '../../GeneratorWhitePage';

const ModalWhitePage = ({ closeModal, show, activeDomain, loadData }) => {
    const closedModal = () => {
        closeModal();
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-white-page">
            <Modal.Header closeButton>
                <Modal.Title>White Page</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="WhitePage">
                    <GeneratorWhitePage activeDomain={activeDomain} closedModal={closedModal} loadWhite={loadData} />
                    <div className="WhitePage-buttons" />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalWhitePage;
