import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import React, { useState } from 'react';
import UIFormInput from '../../UI/UIFormInput';
import { postUuid } from '../../../pages/Bayer/actions';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import './styles.scss';
import { vaultService } from '../../../core/services/vault-service';

const ModalStats = ({ closeModal, show }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [uuid, setUuid] = useState('');
    const [dateRange, setDateRange] = useState([moment().subtract(7, 'days').toDate(), moment().toDate()]);
    const [startDate, endDate] = dateRange;
    const service = vaultService();

    const userId = service.getItem('user').id;

    const dispatch = useDispatch();

    const addedUuid = async () => {
        setIsLoading(true);
        const options = {
            uuid: uuid,
            bayerId: userId,
            dateFrom: moment(startDate).format('YYYY-MM-DD'),
            dateTo: moment(endDate).format('YYYY-MM-DD'),
        };

        const resp = await dispatch(postUuid(options));
        if (resp) {
            const dateFrom = moment(startDate).format('YYYY-MM-DD');
            const dateTo = moment(endDate).format('YYYY-MM-DD');
            const daysCount = moment(dateTo).diff(moment(dateFrom), 'days') + 1;
            NotificationManager.success(
                `Приблизительно через минут ${daysCount}, будет доступна статистика по этому указаному Octo ID`,
                '',
                10000,
            );
            closedModal();
        }
        setIsLoading(false);
    };

    const closedModal = () => {
        closeModal();
    };

    const isDisableButton = () => {
        if (uuid.length === 0) return true;
        return isLoading;
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setUuid(value);
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-gd">
            <Modal.Header closeButton>
                <Modal.Title>Статистика</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row className="d-flex flex-column">
                        <Col xs={12} className="d-flex flex-column gap-2 justify-content-center">
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="domain_name"
                                label={'Введите Octo ID'}
                                value={uuid}
                            />
                            <div className="DatePicker-wrapper">
                                <DatePicker
                                    maxDate={new Date()}
                                    selected={startDate}
                                    onChange={(dates) => {
                                        const [start, end] = dates;
                                        setDateRange([start, end]);
                                    }}
                                    className="DatePicker-stats"
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                />
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <span className="error-title">
                                    Этот домен не был найден, для начала сбора статистики по этому домену нужно ввести Octo ID
                                </span>
                                <span className="error-title">Нужно указать дату по какому периоду нужна статистика</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            variant="primary"
                            onClick={addedUuid}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Подтвердить'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalStats;
