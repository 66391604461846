import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';
import MyProgrev from '../MyProgrev';
import ListDomain from '../ListDomains';
import { useDispatch, useSelector } from 'react-redux';
import { changeTab } from '../actions';
import { getDomainCheck } from '../../SysAdmin/action';
import { vaultService } from '../../../core/services/vault-service';

const PanelFarmer = () => {
    const { tab } = useSelector((state) => state.farmerReducer);
    const [checks, setChecks] = useState([]);

    const service = vaultService();

    const storageTab = service.getItem('tabFarmer');

    const activeTab = storageTab ? storageTab : tab;

    const dispatch = useDispatch();

    const handleChangeTabs = (tab) => {
        dispatch(changeTab(tab));
        service.setItem('tabFarmer', tab);
    };

    const loadData = async () => {
        const check = await dispatch(getDomainCheck());
        setChecks(check);
    };

    const showStatusDomain = (domain) => {
        const check = checks.find((item) => item.name === domain.name);

        switch (check?.status) {
            case 'up':
                return <div className="status-up" />;
            case 'down':
                return <div className="status-down" />;
            case 'unknown':
                return <div className="status-unknown" />;
            default:
                return <div className="status-none" />;
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <Card className="Farmer">
                <Card.Header>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="Farmer-header">
                            <span className={activeTab === 1 ? 'active-tab' : ''} onClick={() => handleChangeTabs(1)}>
                                Список доменов
                            </span>
                            <span className={activeTab === 2 ? 'active-tab' : ''} onClick={() => handleChangeTabs(2)}>
                                Мой прогрев
                            </span>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {activeTab === 1 ? (
                        <ListDomain showStatusDomain={showStatusDomain} />
                    ) : (
                        <MyProgrev showStatusDomain={showStatusDomain} />
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default PanelFarmer;
