/* eslint-disable */
import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import docCookies from '../../core/services/cookie';
import { vaultService } from '../../core/services/vault-service';
import { GET_USER, USER_LOGOUT } from './types';
import { helperError } from '../../core/helpers/helperError';
const service = vaultService();

const clearServicesData = () => {
    docCookies.clearAll();
    service.removeItems('role');
};

const setUserDataToLocalStorage = (accessToken, role, user) => {
    docCookies.setItem('token', accessToken);
    service.setItem('role', role);
    service.setItem('user', user);
    service.removeItems('copy-rules');
};

export const loginByEmail = (email, password) => async (dispatch) => {
    try {
        const authenticationResponse = await API.post('auth/login', {
            email,
            password,
        });
        setUserDataToLocalStorage(
            authenticationResponse.data.access_token,
            authenticationResponse.data.user?.roles[authenticationResponse?.data?.user?.roles.length - 1],
            authenticationResponse.data.user,
        );
        dispatch({
            type: GET_USER,
            payload: authenticationResponse.data.access_token,
        });

        return authenticationResponse;
    } catch (error) {
        helperError(error);
        return error;
    }
};
