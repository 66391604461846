/* eslint-disable */

import { SET_DOMAIN_FARMER, CHANGE_PAGE, SET_DOMAIN_FARMER_WARM, CHANGE_PAGE_WARM, CHANGE_TAB_FARMER } from './types';

const initialState = {
    domains: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    domainsWarm: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    page: 1,
    pageWarm: 1,
    tab: 1,
};

const farmerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DOMAIN_FARMER:
            return { ...state, domains: payload };
        case SET_DOMAIN_FARMER_WARM:
            return { ...state, domainsWarm: payload };
        case CHANGE_PAGE:
            return { ...state, page: payload };
        case CHANGE_PAGE_WARM:
            return { ...state, pageWarm: payload };
        case CHANGE_TAB_FARMER:
            return { ...state, tab: payload };
        default:
            return state;
    }
};

export default farmerReducer;
