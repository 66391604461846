import { Col, Row } from 'react-bootstrap';
import Button from '../../../components/UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { changeStatusDomainWarm, setWhitePageDomain } from '../../Farmer/actions';
import { ROUTES } from '../../../core/helpers/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as InfoNorm } from '../../../assets/images/infoNorm.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { getDomeinsBuyerReserved, getUser } from '../actions';
import { changePagesReserved, getRkCheck, uploadWhitePage } from '../../Bayer/actions';
import UICardContainer from '../../../components/UI/UICardContainer';
import './styles.scss';
import { getDomainCheck } from '../../SysAdmin/action';
import ModalSettingRK from '../../../components/Modals/SettingsRK';
import UIFormInput from '../../../components/UI/UIFormInput';
import moment from 'moment/moment';

const ListDomainBuyer = () => {
    const [isShowRk, setIsShowRk] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [isLoadingServer, setIsLoadingServer] = useState(null);
    const [user, setUser] = useState('');
    const [checks, setChecks] = useState([]);
    const [editRk, setEditRk] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const fileInputRef = useRef(null);
    const [name, setName] = useState('');
    const [isLoadingUpload, setIsLoadingUpload] = useState(null);
    const [currentDomainUpload, setCurrentDomainUpload] = useState(null);
    const timeoutRef = useRef(null);

    const handleFileChange = async (event) => {
        if (!currentDomainUpload) return;
        setIsLoadingUpload(currentDomainUpload);
        const file = event.target.files[0];
        await dispatch(uploadWhitePage(file, currentDomainUpload));
        setIsLoadingUpload(null);
        setCurrentDomainUpload(null);
    };

    const handleClickUploadWhitePage = async (id) => {
        await setCurrentDomainUpload(id);
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    let timer = null;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { userId } = useParams();

    const { domainsReserved, pageReserved } = useSelector((state) => state.buyerReducer);

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesReserved(currentPage));
        await dispatch(getDomeinsBuyerReserved(currentPage, userId, name));
    };

    const loadData = async () => {
        await dispatch(getDomeinsBuyerReserved(pageReserved, userId, name));
    };

    const closeModalRk = () => {
        setIsShowRk(false);
        setSelectedDomain(null);
        setEditRk(false);
    };

    const openModalRk = async (item) => {
        const resp = await dispatch(getRkCheck(item.id));
        if (resp) {
            if (resp.status === 204) {
                setSelectedDomain(item);
                setIsShowRk(true);
                return;
            }
            setSelectedDomain(item);
            setIsShowRk(true);
            setEditRk(true);
            return;
        }
    };

    useEffect(() => {
        loadData();
        loadBuyer();
        loadStatusDomain();
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleClickButtonSetServer = async (item) => {
        if (isLoadingServer !== null) return;
        setIsLoadingServer(item.id);
        const domain = item.id;
        const whitePage = item.domain_data?.white_page?.white_id;
        await dispatch(setWhitePageDomain(domain, whitePage));
        loadData();
        setIsLoadingServer(null);
    };

    const showLink = (domain) => {
        if (
            domain?.domain_data?.thank_you_page?.ty ||
            domain?.domain_data?.success_page?.success ||
            domain?.domain_go_push_history
        ) {
            return (
                <div className="info_norm_buyer">
                    <InfoNorm data-tooltip-id={`my-tooltip-${domain.id}`} />
                    <Tooltip
                        id={`my-tooltip-${domain.id}`}
                        place="bottom"
                        openOnClick
                        clickable
                        style={{
                            zIndex: '9999999999',
                            margin: 0,
                            padding: '10px',
                            paddingBottom: 0,
                        }}
                    >
                        <div className="info_norm_buyer-content">
                            {domain?.domain_data?.success_page?.success && (
                                <p>Витрина: {`https://${domain.name}/${domain?.domain_data?.success_page?.success}`}</p>
                            )}
                            {domain?.domain_data?.thank_you_page?.ty && (
                                <p>Пуш: {`https://${domain.name}/${domain?.domain_data?.thank_you_page?.ty}`}</p>
                            )}
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return false;
    };

    const loadBuyer = async () => {
        const user = await dispatch(getUser(userId));
        if (user) {
            setUser(user?.data?.name);
        }
    };

    const loadStatusDomain = async () => {
        const check = await dispatch(getDomainCheck());
        if (check) {
            setChecks(check);
        }
    };

    const showStatusDomain = (domain) => {
        const check = checks.find((item) => item.name === domain.name);

        switch (check?.status) {
            case 'up':
                return <div className="status-up-domains" />;
            case 'down':
                return <div className="status-down-domains" />;
            case 'unknown':
                return <div className="status-unknown-domains" />;
            default:
                return <div className="status-none-domains" />;
        }
    };

    const handleClickButtonPut = async (item) => {
        if (isLoading !== null) return;
        setIsLoading(item.id);
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: 'free',
            domain_data: item.domain_data,
            user_id: null,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
        setIsLoading(null);
    };

    const handleClickDomain = (name) => {
        navigate(ROUTES.statistic.link.replace(':name', name));
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeinsBuyerReserved(pageReserved, userId, value));
        }, 300);
    };

    const showStatusWhitePage = (domain) => {
        return (
            <div className="info_norm_buyer">
                <Tooltip
                    id={`my-tooltip-status-white-page${domain.id}`}
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0">Статус заливки: {domain?.upload_status ?? '-'}</p>
                        <p className="m-0 p-0">
                            Последняя заливка:{' '}
                            {domain?.uploaded_at ? moment(domain?.uploaded_at).format('DD.MM.YYYY HH:mm:ss') : '-'}
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <UICardContainer title={`Список доменов ${user}`} backLink={ROUTES.listBuyer.link}>
            <div className="ListDomainBuyer">
                <div className="d-flex flex-column">
                    <Row className="border-bottom pb-3 text-bold position-relative">
                        <Col className="ListDomainBuyer-item_header">Name</Col>
                        <Col className="ListDomainBuyer-item_header">Status</Col>
                        <Col className="ListDomainBuyer-item_header"></Col>
                        <Col className="ListDomainBuyer-item_header"></Col>
                        <Col className="ListDomainBuyer-item_header"></Col>
                        <Col className="ListDomainBuyer-item_header"></Col>
                        <Col className="ListDomainBuyer-item_header"></Col>
                        <Col className="ListDomainBuyer-item_header"></Col>
                        <div className="MyDomain-search">
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="name"
                                placeholder="Search domain"
                                value={name}
                            />
                        </div>
                    </Row>

                    {domainsReserved.data.map((item) => (
                        <Row className={`border-bottom pb-3 pt-4 d-flex list`} key={item.id}>
                            <Col className="ListDomainBuyer-item">
                                <div className="ListDomainBuyer-domain" onClick={() => handleClickDomain(item.name)}>
                                    {showStatusDomain(item)}
                                    {item.name}
                                </div>
                            </Col>
                            <Col className="ListDomainBuyer-item">
                                <div>{item.status}</div>
                            </Col>
                            <Col className="ListDomainBuyer-item ml-auto" style={{maxWidth: "140px"}}>
                                <Button blue title="Настроить РК" onClick={() => openModalRk(item)} />
                            </Col>
                            <Col className="MyDomain-item" style={{maxWidth: "140px"}}>
                                {isLoadingUpload === null && (
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, item)}
                                        accept=".zip,.rar"
                                    />
                                )}
                                <Button
                                    blue
                                    title="White Page"
                                    onClick={() => handleClickUploadWhitePage(item.id)}
                                    isLoading={isLoadingUpload === item.id}
                                />
                            </Col>
                            <Col className="MyProgrev-item" data-tooltip-id={`my-tooltip-status-white-page${item.id}`} style={{maxWidth: "120px"}}>
                                <Button
                                    title="Залить"
                                    isLoading={isLoadingServer === item.id}
                                    onClick={() => handleClickButtonSetServer(item)}
                                />
                                {showStatusWhitePage(item)}
                            </Col>
                            <Col className="MyDomain-item" style={{maxWidth: "140px"}}>
                                <Button
                                    title="Открепить"
                                    isLoading={isLoading === item.id}
                                    onClick={() => handleClickButtonPut(item)}
                                />
                            </Col>
                            <Col className="ListDomainBuyer-info">{showLink(item)}</Col>
                        </Row>
                    ))}
                </div>
                <div className="pagination">
                    {domainsReserved.meta?.total > domainsReserved.meta?.per_page && (
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={domainsReserved.meta?.total / domainsReserved.meta?.per_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={1}
                            onPageChange={changePage}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageReserved - 1}
                        />
                    )}
                </div>

                {isShowRk && (
                    <ModalSettingRK
                        show={isShowRk}
                        closeModal={closeModalRk}
                        domain={selectedDomain}
                        userId={userId}
                        edit={editRk}
                        loadDomain={loadData}
                    />
                )}
            </div>
        </UICardContainer>
    );
};

export default ListDomainBuyer;
