import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import { GET_DALLE_MODALS, GET_GPT_MODEL, GET_LOCALES, GET_THEMES, GET_WHITE_PAGES } from './types';
import { helperError } from '../../core/helpers/helperError';

export const createWhitePage = (options) => async () => {
    try {
        const response = await API.post('/microservices/white-page-generator/api/white-pages', options);
        NotificationManager.success(`Генерация началась`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editWhitePage = (options, id) => async () => {
    try {
        const response = await API.put(`/microservices/white-page-generator/api/white-pages/${id}/regenerate`, options);
        NotificationManager.success(`Перегенирация началась`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const generatePrompt = (options) => async () => {
    try {
        const response = await API.post(`/microservices/white-page-generator/api/white-pages/generate-prompt`, options);
        NotificationManager.success(`Промт успешно сгенерирован`, '', 3500);
        return response;
    } catch (e) {
        helperError(e);
    }
};

export const createThankYouPage = (options, id) => async () => {
    try {
        const response = await API.post(`/microservices/servers-managment/api/v1/thank-you-pages/thank-you/${id}`, options);
        NotificationManager.success(`Успешно создан thank you page`, '', 3500);
        return response;
    } catch (e) {
        helperError(e);
    }
};

export const createSuccessPage = (options, id) => async () => {
    try {
        const response = await API.post(`/microservices/servers-managment/api/v1/thank-you-pages/success/${id}`, options);
        NotificationManager.success(`Успешно создан success page`, '', 3500);
        return response;
    } catch (e) {
        helperError(e);
    }
};

export const getThemes = () => async (dispatch) => {
    try {
        const response = await API.get('/microservices/white-page-generator/api/directories/themes');
        dispatch({ type: GET_THEMES, payload: response.data });
    } catch (e) {
        helperError(e);
    }
};

export const getWhitePages = (page) => async (dispatch) => {
    try {
        const response = await API.get(`/microservices/white-page-generator/api/white-pages?page=${page}`);
        dispatch({ type: GET_WHITE_PAGES, payload: response.data });
        return response.data.data;
    } catch (e) {
        helperError(e);
    }
};

export const getWhitePage = (id) => async () => {
    try {
        const response = await API.get(`/microservices/white-page-generator/api/white-pages/${id}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getWhitePagePreview = (id) => async () => {
    try {
        const response = await API.get(`/microservices/white-page-generator/api/white-pages/${id}/preview`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const downloadWhitePage = (whitePage) => async () => {
    try {
        const response = await API.get(`/microservices/white-page-generator/api/white-pages/${whitePage}/download`, {
            responseType: 'arraybuffer',
        });
        return response;
    } catch (e) {
        helperError(e);
    }
};

export const regenerateWhitePage = (whitePage, options) => async () => {
    try {
        await API.put(`/microservices/white-page-generator/api/white-pages/${whitePage}/regenerate`, options);
        NotificationManager.success(`Генерация началась`, '', 3500);
    } catch (e) {
        helperError(e);
    }
};

export const getLocales = () => async (dispatch) => {
    try {
        const response = await API.get('/microservices/white-page-generator/api/directories/locales');
        dispatch({ type: GET_LOCALES, payload: response.data });
    } catch (e) {
        helperError(e);
    }
};

export const getGptModel = () => async (dispatch) => {
    try {
        const response = await API.get('/microservices/white-page-generator/api/directories/chat-gpt-models');
        dispatch({ type: GET_GPT_MODEL, payload: response.data });
    } catch (e) {
        helperError(e);
    }
};

export const getDalleModels = () => async (dispatch) => {
    try {
        const response = await API.get('/microservices/white-page-generator/api/directories/dalle-models');
        dispatch({ type: GET_DALLE_MODALS, payload: response.data });
    } catch (e) {
        helperError(e);
    }
};
