import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vaultService } from '../../core/services/vault-service';
import { changeTabAggregator, getAccountList } from './actions';
import { Button, Card } from 'react-bootstrap';
import './AggregatorMail.scss';
import AccountList from './AccountList';
import MessageList from './MessageList';
import ModalCreateAccount from '../../components/Modals/ModalCreateAccount';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';

const AggregatorMail = () => {
    const { tab } = useSelector((state) => state.aggregatorReducer);
    const { pageAccount } = useSelector((state) => state.aggregatorReducer);
    const [isShowAddedAccount, setIsShowAddedAccount] = useState(false);
    const [pendingEmail, setPendingEmail] = useState(null);
    const navigate = useNavigate();

    const service = vaultService();

    const storageTab = service.getItem('tabAggregator');

    const activeTab = storageTab ? storageTab : tab;

    const dispatch = useDispatch();

    const handleOpenModal = () => {
        setIsShowAddedAccount(!isShowAddedAccount);
    };

    const handleCloseModal = () => {
        setIsShowAddedAccount(!isShowAddedAccount);
        setPendingEmail(null);
    };

    const handleChangeTabs = (tab) => {
        dispatch(changeTabAggregator(tab));
        service.setItem('tabAggregator', tab);
    };

    const handleClickButtonPending = (item) => {
        setPendingEmail(item);
        handleOpenModal();
    };

    const loadData = async () => {
        await dispatch(getAccountList(pageAccount));
    };

    const handleClickBack = () => {
        navigate(ROUTES.sysAdminPanel.path);
    };

    return (
        <>
            <Card className="Aggregator">
                <Card.Header>
                    <div className="Aggregator-buttons mb-3 ml-2">
                        <Button variant="secondary" onClick={handleClickBack}>
                            Назад
                        </Button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="Aggregator-header">
                            <span className={activeTab === 1 ? 'active-tab' : ''} onClick={() => handleChangeTabs(1)}>
                                Список акаунтов
                            </span>
                            <span className={activeTab === 2 ? 'active-tab' : ''} onClick={() => handleChangeTabs(2)}>
                                Список сообщений
                            </span>
                        </div>
                        {activeTab === 1 && (
                            <div className="Aggregator-buttons">
                                <Button onClick={handleOpenModal}>Добавить акаунт</Button>
                            </div>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    {activeTab === 1 && <AccountList handleClickButtonPending={handleClickButtonPending} />}
                    {activeTab === 2 && <MessageList />}
                </Card.Body>

                {isShowAddedAccount && (
                    <ModalCreateAccount
                        pendingEmail={pendingEmail}
                        show={isShowAddedAccount}
                        closeModal={handleCloseModal}
                        loadData={loadData}
                    />
                )}
            </Card>
        </>
    );
};

export default AggregatorMail;
