import { Button, Modal, Spinner } from 'react-bootstrap';

const ModalAccept = ({ closeModal, show, title, regenerate, isLoading }) => {
    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton id="test-modal-header">
                <Modal.Title style={{ fontSize: '20px' }}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal} disabled={isLoading}>
                    Отмена
                </Button>
                <Button style={{ width: '52px', height: '37.5px' }} variant="primary" onClick={regenerate} disabled={isLoading}>
                    {isLoading ? <Spinner size="sm" animation="border" /> : 'Да'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAccept;
