import { helperError } from '../../../core/helpers/helperError';
import { API } from '../../../core/api';

export const getServices = (domain) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domain}/services`);

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getListServices = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/services/list`);

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getAccountsBinom = (service) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/services/${service}/account/list`);

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const activatedService = (options, service, domain) => async () => {
    try {
        const response = await API.post(
            `microservices/filtering/api/v1/filtering/domain/${domain}/services/${service}/user`,
            options,
        );

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const changeDataService = (options, service, domain) => async () => {
    try {
        const response = await API.put(
            `microservices/filtering/api/v1/filtering/domain/${domain}/services/update-config/${service}`,
            options,
        );

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const changeActivatedService = (options, domain) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domain}/services`, options);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const getListBinomService = (service) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/services/${service}/account/list`);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const binomConnectService = (service, userId, options) => async () => {
    try {
        const response = await API.post(`microservices/filtering/api/v1/filtering/services/${service}/account/connect`, options);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const getStatic = (info) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/static/get/${info}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};
