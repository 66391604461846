import { CHANGE_PAGE_ACCOUNT, CHANGE_PAGE_MESSAGE, CHANGE_TAB_AGGREGATOR, SET_ACCOUNTS, SET_MESSAGE } from './types';
import { API } from '../../core/api';
import { helperError } from '../../core/helpers/helperError';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

export const changePagesAccount = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE_ACCOUNT, payload: page });
};

export const changeTabAggregator = (tab) => async (dispatch) => {
    dispatch({ type: CHANGE_TAB_AGGREGATOR, payload: tab });
};

export const changePagesMessage = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE_MESSAGE, payload: page });
};

export const getMessageList = (page, filters, search) => async (dispatch) => {
    const [minDate, maxDate] = filters.date || [];

    const formatDate = (date, isMax) => {
        if (!date) return null;

        if (isMax) {
            return moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        } else {
            return moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
    };

    const params = {
        page: page,
        emailAccountIds: filters.account,
        search,
    };

    if (minDate) {
        params.minDate = formatDate(minDate, false);
    }

    if (maxDate) {
        params.maxDate = formatDate(maxDate, true);
    }

    try {
        const response = await API.get(`/microservices/email-agregator/api/v1/email-messages/consume`, {
            params: params,
        });

        dispatch({ type: SET_MESSAGE, payload: response.data });

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getFreeAccount = () => async () => {
    try {
        const response = await API.post(`/microservices/email-agregator/api/v1/email-accounts/get-free-email-account`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const disableAccount = (email) => async () => {
    try {
        const response = await API.put(`/microservices/email-agregator/api/v1/email-accounts/${email}/disable`);
        NotificationManager.success(`Акаунт отключен`, '', 3000);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const verifyAccount = (email) => async () => {
    try {
        const response = await API.put(
            `/microservices/email-agregator/api/v1/email-accounts/${email}/verify-forwarding-settings`,
        );
        NotificationManager.success(`Акаунт верефицирован успешно`, '', 3000);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getMessageAccount = (email) => async () => {
    try {
        const response = await API.get(`/microservices/email-agregator/api/v1/email-accounts/${email}/read-messages`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getAccountList = (page) => async (dispatch) => {
    try {
        const response = await API.get(`/microservices/email-agregator/api/v1/email-accounts/?page=${page}`);
        dispatch({ type: SET_ACCOUNTS, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};
