import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePicker.scss';

const CustomDatePicker = ({ title, onChange, value }) => {
    return (
        <div className="CustomDatePicker">
            <label>{title}</label>
            <DatePicker selected={value} className="CustomDatePicker-input" onChange={onChange} />
        </div>
    );
};

export default CustomDatePicker;
