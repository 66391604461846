import { AxiosError } from 'axios';
import { NotificationManager } from 'react-notifications';

export const helperError = (error) => {
    const defaultMessage = 'An unexpected error occurred';
    if (error instanceof AxiosError) {
        const message = error.response?.data.message || error.message || defaultMessage;
        NotificationManager.error(`${message}`, '', 3500);
        return message;
    } else {
        NotificationManager.error(`${defaultMessage}`, '', 3500);
        return defaultMessage;
    }
};
