import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import { registerAccount } from './actions';
import { NotificationManager } from 'react-notifications';
import './styles.scss';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [pincode, setPincode] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        setEmail(value);
    };

    const handleChangePassword = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const handleChangeName = (e) => {
        const value = e.target.value;
        setName(value);
    };

    const handleChangePinCode = (e) => {
        const value = e.target.value;
        setPincode(value);
    };

    const submitClickHandler = async () => {
        if (password !== pincode) {
            NotificationManager.error('Пароли не совпадают', '', 3500);
            return;
        }
        const resp = await dispatch(registerAccount(email, password, name, pincode));
        if (resp) {
            navigate(ROUTES.authorization.link);
        }
    };

    const submitClickCancel = async () => {
        navigate(ROUTES.authorization.link);
    };

    const validationEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length === 0) return null;
        if (!emailRegex.test(email)) return 'Не корректный Email';
        return null;
    };

    const validationName = () => {
        if (name.length === 0) return null;
        if (name.length > 10) return 'Имя пользователя должно быть не больше 10 символов';
        return null;
    };

    const isDisabledButton = () => {
        if (name.length === 0) return true;
        if (password.length === 0) return true;
        if (pincode.length === 0) return true;
        if (email.length === 0) return true;
        if (typeof validationEmail() === 'string') return true;
        if (typeof validationName() === 'string') return true;
        return false;
    };

    return (
        <div style={{ height: '100vh' }} className="d-flex flex-column justify-content-center align-items-center">
            <div className="ml-auto mr-auto">
                <div className="border-bottom pb-4">
                    <h1 className="text-silver">Регистрация</h1>
                </div>

                <div className="d-flex gap-4 mt-4 align-items-center justify-content-center position-relative">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Имя"
                        value={name}
                        autoComplete="off"
                        style={{ width: '367px', height: '50px' }}
                        onChange={handleChangeName}
                    />
                    {validationName() && <span className="error-message-name">{validationName()}</span>}
                    <input
                        className={`form-control ${typeof validationEmail() === 'string' ? 'error' : ''}`}
                        type="text"
                        placeholder="Почта"
                        value={email}
                        style={{ width: '367px', height: '50px' }}
                        onChange={handleChangeEmail}
                        autoComplete="new-email"
                    />
                    {validationEmail() && <span className="error-message">{validationEmail()}</span>}
                </div>
                <div className="d-flex gap-4 mt-4 align-items-center justify-content-center border-bottom pb-4">
                    <input
                        className="form-control"
                        type="password"
                        placeholder="Пароль"
                        style={{ width: '367px', height: '50px' }}
                        value={password}
                        autoComplete="new-password"
                        onChange={handleChangePassword}
                    />
                    <input
                        className="form-control"
                        type="password"
                        autoComplete="off"
                        placeholder="Повторите пароль"
                        style={{ width: '367px', height: '50px' }}
                        value={pincode}
                        onChange={handleChangePinCode}
                    />
                </div>
                <div className="mt-4 d-flex gap-4 align-items-center justify-content-end">
                    <div className="d-flex align-items-center gap-4">
                        <Button
                            style={{ height: '40px', width: '150px' }}
                            className="ml-auto"
                            variant="primary"
                            onClick={submitClickCancel}
                        >
                            Отмена
                        </Button>
                        <Button
                            style={{ height: '40px', width: '180px' }}
                            className="ml-auto"
                            variant="primary"
                            disabled={isDisabledButton()}
                            onClick={submitClickHandler}
                        >
                            Зарегестрироваться
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
