import './InfoSettingForwarding.scss';
import infoFirst from '../../assets/images/info/info-1.png';
import infoSecond from '../../assets/images/info/info-2.png';
import infoThird from '../../assets/images/info/info-3.png';
import infoFour from '../../assets/images/info/info-4.png';

const InfoSettingForwarding = () => {
    return (
        <div className="InfoSettingForwarding">
            <div className="InfoSettingForwarding-title">Настройка переадресации</div>
            <div className="InfoSettingForwarding-item">
                <span>1. В аккаунте открываем настройки </span>
                <img src={infoFirst} alt="" />
            </div>
            <div className="InfoSettingForwarding-item">
                <span>2. Выбираем раздел Перессылка и нажимаем “Добавить адрес для перессылки”</span>
                <img src={infoSecond} alt="" />
            </div>
            <div className="InfoSettingForwarding-item">
                <span>3. Вписываем адрес, который получили в окне </span>
            </div>
            <div className="InfoSettingForwarding-item">
                <span>
                    4. Возвращаемся в 13tools и нажимаем кнопку “Прочитать сообщение”, там будет ссылка на подтверждение
                    переадресации. Открываем её и подтверждаем{' '}
                </span>
                <img src={infoThird} alt="" />
            </div>
            <div className="InfoSettingForwarding-item">
                <span>5. Обновляем странницу и добавляем нужный адрес ещё раз и нажимаем Сохранить </span>
                <img src={infoFour} alt="" />
            </div>
        </div>
    );
};

export default InfoSettingForwarding;
