import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import { helperError } from '../../core/helpers/helperError';

export const registerAccount = (email, password, name, pincode) => async () => {
    const options = {
        email,
        password,
        name,
        password_confirmation: pincode,
    };
    try {
        const authenticationResponse = await API.post('auth/register', options);
        NotificationManager.success(`Регистрация пройшла успешно`, '', 3500);
        return authenticationResponse;
    } catch (error) {
        helperError(error);
        return error;
    }
};
