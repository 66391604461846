import './GeneratorsWhitePage.scss';
import { ReactComponent as Mobirise } from '../../assets/images/Mobirise.svg';
import { ReactComponent as Webwave } from '../../assets/images/Webwave.svg';
import { ReactComponent as WhiteGen } from '../../assets/images/WhiteGen.svg';
import { ReactComponent as NPPR } from '../../assets/images/NPPR.svg';
import { ReactComponent as Glass } from '../../assets/images/glass.svg';
import { ReactComponent as Utka } from '../../assets/images/utka.svg';
import { ReactComponent as Comsign } from '../../assets/images/Comsign.svg';
import { ReactComponent as MoarTools } from '../../assets/images/MoarTools.svg';
import MobiraseInstruction from '../../assets/images/instructions/MobiraseInstruction.png';
import WebwaveInstruction from '../../assets/images/instructions/WebwaveInstruction.png';
import ComsingInstruction from '../../assets/images/instructions/ComsingInstruction.png';
import UtkaInstruction from '../../assets/images/instructions/UtkaInstruction.png';
import NPPRInstruction from '../../assets/images/instructions/NPPRInstruction.png';
import CukerInstruction from '../../assets/images/instructions/CukerInstruction.png';
import WhiteGenInstruction from '../../assets/images/instructions/WhiteGenInstruction.png';

import { useState } from 'react';

const GeneratorsWhitePage = () => {
    const [step, setStep] = useState(0);

    const handleClickMoarTools = () => {
        window.open('http://95.216.4.23:3000', '_blank');
    };

    return (
        <div className="GeneratorsWhitePage">
            {step !== 0 && (
                <span onClick={() => setStep(0)} className="Back">
                    Назад
                </span>
            )}
            {step === 0 && (
                <div className="GeneratorsWhitePage-list">
                    <div className="GeneratorsWhitePage-item" onClick={handleClickMoarTools}>
                        <MoarTools />
                        <span className="GeneratorsWhitePage-item-title">Moar Tools</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(1)}>
                        <Mobirise />
                        <span className="GeneratorsWhitePage-item-title">Mobirise</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(2)}>
                        <Webwave />
                        <span className="GeneratorsWhitePage-item-title">Webwave</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(3)}>
                        <WhiteGen />
                        <span className="GeneratorsWhitePage-item-title">WhiteGen Bot</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(4)}>
                        <NPPR />
                        <span className="GeneratorsWhitePage-item-title">NPPR</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(5)}>
                        <Glass />
                        <span className="GeneratorsWhitePage-item-title">Глаз Цукера</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(6)}>
                        <Utka />
                        <span className="GeneratorsWhitePage-item-title">Уточка Петти</span>
                    </div>
                    <div className="GeneratorsWhitePage-item" onClick={() => setStep(7)}>
                        <Comsign />
                        <span className="GeneratorsWhitePage-item-title">Comsign</span>
                    </div>
                </div>
            )}

            {step === 1 && <img src={MobiraseInstruction} alt="" className="GeneratorsWhitePage-image" />}
            {step === 2 && <img src={WebwaveInstruction} alt="" className="GeneratorsWhitePage-image" />}
            {step === 3 && <img src={WhiteGenInstruction} alt="" className="GeneratorsWhitePage-image" />}
            {step === 4 && <img src={NPPRInstruction} alt="" className="GeneratorsWhitePage-image" />}
            {step === 5 && <img src={CukerInstruction} alt="" className="GeneratorsWhitePage-image" />}
            {step === 6 && <img src={UtkaInstruction} alt="" className="GeneratorsWhitePage-image" />}
            {step === 7 && <img src={ComsingInstruction} alt="" className="GeneratorsWhitePage-image" />}
        </div>
    );
};

export default GeneratorsWhitePage;
